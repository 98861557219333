// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { Mixpanel } from "@configs";
import api from "@apicall/servicesV4/api";

const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action?.payload;

      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem("deviceToken", action.payload?.deviceToken);
      localStorage.setItem(
        "restaurant",
        JSON.stringify({
          name: action?.payload?.workPlaces[0]?.name,
          _id: action?.payload?.workPlaces[0]?._id
        })
      );
    },
    handleLogout: (state) => {
      const data = JSON.parse(localStorage.getItem("userData"));
      Mixpanel.identify(data?.email?.length ? data?.email[0] : "", {
        $email: data?.email,
        $user_id: data?._id || "",
        $name: data?.name?.first + data?.name?.last,
        $phone_number: data?.contact
      });
      Mixpanel.track(`Successful logout`);
      Mixpanel.people.set({
        $email: data?.email,
        $user_id: data?._id,
        $first_name: data?.name?.first,
        $last_name: data?.name?.last,
        $phone_number: data?.contact
      });
      state.userData = {};
      // Clear all local storage data and session storage data

      // localStorage.removeItem("userData");
      // localStorage.removeItem("tempUserData");
      // localStorage.removeItem("token");
      // localStorage.removeItem("tempToken");
      // localStorage.removeItem("undefined");
      // localStorage.removeItem("restaurant");
      // localStorage.removeItem("orderCount");
      // localStorage.removeItem("unauthorized");
      localStorage.clear();

      sessionStorage.clear();
    }
  }
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
