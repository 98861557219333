import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const CreateDishContext = React.createContext({});

const CreateDishProvider = ({ children }) => {
  const emptyDish = () => {
    return {
      itemName: "",
      variants: [
        {
          name: "",
          price: "",
          discountedPrice: "",
          isDefault: true
        }
      ],
      addedMenuId: [""],
      addedCategoryId: [""],
      libraryImageUrl: ""
    };
  };
  const [libraryOpen, setLibraryOpen] = useState(false);
  const [bulkLibraryOpen, setBulkLibraryOpen] = useState(false);
  const [bulkDishOpen, setBulkDishOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [dishDetail, setDishDetail] = useState({
    itemName: "",
    price: "",
    discountedPrice: "",
    discountPrice: "",
    menu: "",
    category: "",
    description: ""
  });
  const [variant, setVariant] = useState([]);
  const [extraAddOns, setExtraAddOns] = useState([]);
  const [dishImage, setDishImage] = useState([]);
  const [libraryImage, setLibraryImage] = useState({});

  const [inventoryModal, setInventoryModal] = useState(false);
  const [addonsInventory, setAddonsInventory] = useState(false);
  const [defaultVariant, setDefaultVariant] = useState({
    name: "",
    price: "",
    discountedPrice: "",
    discountPrice: "",
    isDefault: true
  });
  const [variantIndex, setVariantIndex] = useState(null);
  const [addonsIndex, setAddonsIndex] = useState(null);
  const [variantOpen, setVariantOpen] = useState(false);
  const [addOnOpen, setAddOnOpen] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [menuOption, setMenuOption] = useState([]);
  const [categoryOption, setCategoryOption] = useState([]);
  const [bulkDish, setBulkDish] = useState([emptyDish(), emptyDish()]);
  const [bulkImgIndex, setBulkImgIndex] = useState();
  const history = useHistory();

  const handleClearFields = (changeRoute = true) => {
    setDishDetail({
      itemName: "",
      price: "",
      discountedPrice: "",
      discountPrice: "",
      menu: "",
      category: "",
      description: ""
    });
    setVariant([]);
    setExtraAddOns([]);
    setDishImage([]);
    setLibraryImage({});
    setDefaultVariant([]);
    setVariantOpen(false);
    setRemoveImage(false);
    if (changeRoute) {
      history.push("/menu-management/all-dishes");
    }
  };

  return (
    <CreateDishContext.Provider
      value={{
        libraryOpen,
        bulkLibraryOpen,
        bulkDishOpen,
        categoryOpen,
        menuOpen,
        dishDetail,
        variant,
        extraAddOns,
        dishImage,
        inventoryModal,
        defaultVariant,
        variantIndex,
        variantOpen,
        addonsIndex,
        addonsInventory,
        libraryImage,
        toggle,
        addOnOpen,
        removeImage,
        menuOption,
        categoryOption,
        bulkDish,
        bulkImgIndex,
        setLibraryOpen,
        setBulkLibraryOpen,
        setBulkDishOpen,
        setCategoryOpen,
        setMenuOpen,
        setDishDetail,
        setVariant,
        setExtraAddOns,
        setDishImage,
        setInventoryModal,
        setDefaultVariant,
        setVariantIndex,
        setVariantOpen,
        setAddonsIndex,
        setAddonsInventory,
        setLibraryImage,
        setToggle,
        handleClearFields,
        setAddOnOpen,
        setRemoveImage,
        setMenuOption,
        setCategoryOption,
        setBulkDish,
        emptyDish,
        setBulkImgIndex
      }}
    >
      {children}
    </CreateDishContext.Provider>
  );
};

export { CreateDishContext, CreateDishProvider };
