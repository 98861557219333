import React, { forwardRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { useSkin, useCurrency } from "@hooks";
import "./styles.scss";

export const ToolTip = forwardRef((props, ref) => {
  const { number, placement, target, children, ...rest } = props;
  const { formatCurrency } = useCurrency();
  const { skin } = useSkin();
  return (
    <UncontrolledTooltip
      ref={ref}
      placement={placement || "top"}
      target={target}
      style={{
        backgroundColor: skin === "light" ? "#fcfcfc" : "#373737",
        color: skin === "light" ? "#000" : "#fff",
        border: skin === "light" ? "1px solid #ebe9f1" : "1px solid #373737 ",
        boxShadow: "0 4px 10px 0 rgba(34, 41, 47, 0.1)"
      }}
      {...rest}
    >
      {number !== undefined ? formatCurrency(number || 0) : children}
    </UncontrolledTooltip>
  );
});
