import { getApiResponse } from "@apicall";

const getAllInvenoryGroupApi = async (props = {}) => {
  const {
    page = 1,
    sort = undefined,
    count = 25,
    forDropDown = undefined,
  } = props;
  const inventoryGroupRes = await getApiResponse({
    url: `/inventory/group`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    ofRestaurant: true,
    otherParams: {
      page,
      sort,
      count,
      forDropDown,
    },
  });

  if (inventoryGroupRes.APIFailed) return null;
  return inventoryGroupRes.data;
};

export default getAllInvenoryGroupApi;
