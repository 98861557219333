import React, { useState, useEffect } from "react";
import fetchDepartmentApi from "@apicall/services/department/fetchAllDepartment";
import fetchStaffDepartmentApi from "@apicall/services/department/fetchStaffDepartments";

export const useDepartment = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const getAllDepartment = async () => {
      const response = await fetchDepartmentApi({ page: 1, count: 100 });
      if (response) {
        const normalizedDepartment = Array.from(
          response?.docs || [],
          (department) => {
            return {
              label: department?.departmentName,
              value: department?._id,
              id: department?._id,
              name: department?.departmentName
            };
          }
        );
        setDepartments(normalizedDepartment);
      }
    };
    getAllDepartment();
  }, []);

  return { departments };
};

export const useStaffDepartment = () => {
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const getAllDepartment = async () => {
      const response = await fetchStaffDepartmentApi();
      if (response) {
        const normalizedDepartment = Array.from(
          response?.departments || [],
          (department) => {
            return {
              label: department?.departmentName || "",
              value: department?._id,
              id: department?._id,
              name: department?.departmentName
            };
          }
        );
        setDepartments(normalizedDepartment);
      }
    };
    getAllDepartment();
  }, []);

  return { departments };
};
