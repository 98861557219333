import React, { useState } from "react";

export const useSelectIds = () => {
  const [ids, setIds] = useState([]);

  const handleSelect = (selectFor = "single", allData = [], data = {}) => {
    if (!allData?.length) return;
    if (selectFor === "single") {
      if (!data?._id) return;

      const value = ids?.find((value) => value === data?._id);
      if (value) {
        const _data = ids.filter((val) => val !== data?._id);
        setIds(_data);
      } else {
        setIds([...ids, data?._id]);
      }
    }
    if (selectFor === "bulk") {
      if (ids?.length === allData?.length) {
        setIds([]);
      } else {
        const allIds = allData?.map((val) => val?._id);
        setIds(allIds);
      }
    }
  };

  return { ids, handleSelect, setIds };
};
