import { useEffect, useContext, useState } from "react";
import { getDishDetail } from "@apicall/services/allDishes/addDishApi";
import { formatDishDetail, isDefaultvariant, getDishPrice } from "@utils";
import { CreateDishContext } from "@context";

const useDishDetail = (dishId) => {
  const {
    setDishDetail,
    setVariant,
    setExtraAddOns,
    setLibraryImage,
    setDefaultVariant,
    setDishImage,
    setAddOnOpen,
    setVariantOpen
  } = useContext(CreateDishContext);
  const [loading, setLoading] = useState(true);
  const [initialDishData, setInitialDishData] = useState({});
  const [menuCategory, setMenuCategory] = useState({});

  useEffect(() => {
    if (dishId) {
      const getDishData = async () => {
        const response = await getDishDetail(dishId);
        if (response) {
          const { dishData, menuCategory } = formatDishDetail(response);
          setInitialDishData(JSON.stringify(dishData));
          setMenuCategory(menuCategory);
          const isDefault = isDefaultvariant(
            response?.item?.variants,
            response?.item?.itemName
          );
          if (isDefault) {
            setDishDetail({
              itemName: dishData?.itemName,
              description: dishData?.description,
              menu: dishData?.addedMenuId,
              category: dishData?.addedCategoryId,
              price: getDishPrice(dishData?.variants[0]?.price) || "",
              discountedPrice:
                getDishPrice(dishData?.variants[0]?.discountedPrice) || "",
              discountPrice:
                Number(getDishPrice(dishData?.variants[0]?.price) || 0) -
                Number(
                  getDishPrice(dishData?.variants[0]?.discountedPrice) || 0
                )
            });
            setDefaultVariant(dishData?.variants[0] || {});
            setVariant(dishData.variants);
          } else {
            setDishDetail({
              itemName: dishData?.itemName,
              description: dishData?.description,
              menu: dishData?.addedMenuId,
              category: dishData?.addedCategoryId
            });
            setVariantOpen(true);
            setVariant(dishData.variants);
          }
          if (dishData?.addons?.length) {
            setExtraAddOns(dishData.addons);
            setAddOnOpen(true);
          }
          setLibraryImage({ image: dishData?.libraryImageUrl });
          setLibraryImage({ image: dishData?.libraryImageUrl });
          setDishImage(response?.item?.images || []);
        }
        setLoading(false);
      };
      getDishData();
    }
  }, [dishId]);

  return { loading, menuCategory, initialDishData };
};

export { useDishDetail };
