import React, { useState } from "react";

const RefreshAppContext = React.createContext({});

const RefreshAppProvider = ({ children }) => {
  const [refresh, setRefresh] = useState(false);

  return (
    <RefreshAppContext.Provider value={{ refresh, setRefresh }}>
      {children}
    </RefreshAppContext.Provider>
  );
};

export { RefreshAppContext, RefreshAppProvider };
