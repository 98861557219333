import { useState, useEffect, useCallback, useContext } from "react";
import getRestaurantInfo from "@src/apicall/services/restaurantDetail";
import { useDispatch, useSelector } from "react-redux";
import switchRestaurantAlert from "../../@core/layouts/components/navbar/userDropdown/switchRestaurantAlert/switchRestaurantAlert";
import getAllRestaurantsApi from "@apicall/services/restaurant/getAllRestaurants";
import setRestaurantActiveApi from "@apicall/services/restaurant/setRestaurantActive";
import { SwitchRestaurantContext } from "@context";
import { useSkin } from "@hooks/useSkin";
import { refetchUserData } from "@store/userDataSlice/userDataSlice";
import { useHistory } from "react-router-dom";
import { createSelector } from "reselect";

const selectUserData = createSelector(
  (state) => state?.userDataSlice?.userData,
  (userData) => userData
);

const useRestaurantInfo = () => {
  const { setRestaurantSwitched, setData } = useContext(
    SwitchRestaurantContext
  );
  const userData = useSelector(selectUserData);
  const activeRestaurant = useSelector(
    (state) => state?.userDataSlice?.userData?.activeRestaurant
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { skin } = useSkin();

  const [toggle, setToggle] = useState(false);
  const [restaurantInfo, setRestaurantInfo] = useState({});
  const [restaurantList, setRestaurantList] = useState([]);

  const getInfo = useCallback(async () => {
    const response = await getRestaurantInfo();
    if (response) {
      setRestaurantInfo(response?.restauarantDetails || {});
    }
  }, []);

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const getAllRestaurant = useCallback(async () => {
    const restaurantDataResponse = await getAllRestaurantsApi({
      userId: userData?._id
    });

    setRestaurantList(restaurantDataResponse?.restaurants);
  }, [activeRestaurant, userData]);

  useEffect(() => {
    getAllRestaurant();
  }, [getAllRestaurant]);

  const switchActiveRestaurant = useCallback(
    async (id, name, image) => {
      if (id === activeRestaurant?.restaurantId) return;
      const msg = `Are you sure you want to swap your Active Restaurant to "${name}"?`;
      const { isConfirmed } = await switchRestaurantAlert(msg, skin);
      if (!isConfirmed) return;
      const restaurantResData = await setRestaurantActiveApi({
        userId: userData._id,
        restaurantId: id
      });

      if (restaurantResData) {
        localStorage.removeItem("unauthorized");
        dispatch(refetchUserData());
        setData({ name, image });
        setRestaurantSwitched(true);
        history.push("/analytics");
      }
    },
    [activeRestaurant, userData, dispatch, setData, history, skin]
  );

  return {
    restaurantInfo,
    setToggle,
    toggle,
    restaurantList,
    setRestaurantList,
    switchActiveRestaurant
  };
};

export default useRestaurantInfo;
