import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getRestroConnectInfo } from "@apicall/services/connect";
import {
  updateConnectSettingApi,
  updateConnectVisibilityApi
} from "../../apicall/services/connect";

const RestroXConnectContext = React.createContext({});

const RestroXConnectProvider = ({ children }) => {
  const [initialData, setInitialData] = useState({});
  const activeRestaurant = useSelector(
    (state) => state?.userDataSlice?.userData?.activeRestaurant
  );
  const [loading, setLoading] = useState(true);
  const [isNewProfile, setIsNewProfile] = useState(true);
  const [profileImg, setProfileImage] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [imageInfo, setImageInfo] = useState({
    coverImg: "",
    profileImg: "",
    logo: ""
  });
  const [coverImg, setCoverImg] = useState([]);
  const [logo, setLogo] = useState([]);
  const [visibility, setVisibility] = useState(true);
  const [basicInfo, setBasicInfo] = useState({
    restaurantName: activeRestaurant?.name || "",
    userName: "",
    description: "",
    address: "",
    review: "",
    website: "",
    email: ""
  });
  const [contact, setContact] = useState({
    primary: {
      dialCode: activeRestaurant?.dialCode || "",
      contact: `${activeRestaurant?.dialCode || ""}${
        activeRestaurant?.contact || ""
      }`
    },
    secondary: { dialCode: "", contact: "" }
  });
  const [socialLink, setSocialLink] = useState([]);
  const [setting, setSetting] = useState({
    help_section: true,
    menu: true,
    own_delivery: true,
    photos: true,
    social_profile: true,
    special_package: true,
    third_party: true,
    special_dish: true,
    explore_dish: true,
    category: true,
    gallery: true
  });
  const [menuImages, setMenuImages] = useState([]);
  const [thirdPartyDelivery, setThirdPartyDelivery] = useState([]);
  const [categories, setCategories] = useState([]);
  const [popularDish, setPopularDish] = useState([]);
  const [specialDish, setSpecialDish] = useState([]);
  const [gallery, setGallery] = useState({ album: [], gallery: [] });

  const handleConnectStatus = async (statusFor, value) => {
    const payload = {
      modules: [{ [statusFor]: !Boolean(value) }]
    };

    const response = await updateConnectSettingApi(payload);
    if (response) {
      setSetting({ ...setting, [statusFor]: !Boolean(value) });
    }
  };

  const handleConnectVisibility = async (value) => {
    const response = await updateConnectVisibilityApi({ isVisible: value });
    if (response) {
      setVisibility(value);
      setToggle(!toggle);
    }
  };

  const handleRedirect = (url) => {
    if (!url) return;
    if (url.startsWith("https://") || url.startsWith("http://")) {
      window.open(url, "_blank");
    } else {
      window.open(`https://${url}`, "_blank");
    }
  };

  useEffect(() => {
    const getInfo = async () => {
      const response = await getRestroConnectInfo();
      if (response) {
        setInitialData(response?.restroConnect || {});
        const info = response?.restroConnect || {};
        if (!Object.keys(info)?.length) {
          setIsNewProfile(true);
          setLoading(false);
          return;
        }
        setIsNewProfile(false);
        setImageInfo({
          coverImg: info?.coverPhoto || "",
          profileImg: info?.profilePicture || "",
          logo: info?.logo || ""
        });
        setBasicInfo({
          restaurantName: info?.restaurantName || "",
          userName: info?.userName || "",
          description: info?.restroBio || "",
          address: info?.googleMapAddress || "",
          review: info?.googleReview || "",
          website: info?.website || "",
          email: info?.email || ""
        });
        setContact({
          primary: {
            dialCode:
              info?.primaryContact?.code || activeRestaurant?.dialCode || "",
            contact: `${
              info?.primaryContact?.code || activeRestaurant?.dialCode || ""
            }${
              info?.primaryContact?.contact || activeRestaurant?.contact || ""
            }`
          },
          secondary: {
            dialCode: info?.secondaryContact?.code || "",
            contact: `${info?.secondaryContact?.code || ""}${
              info?.secondaryContact?.contact || ""
            }`
          }
        });
        setSetting(
          info?.restroConnectSetting || {
            help_section: true,
            menu: true,
            own_delivery: true,
            photos: true,
            social_profile: true,
            special_package: true,
            third_party: true,
            special_dish: true,
            explore_dish: true,
            category: true,
            gallery: true
          }
        );
        setVisibility(Boolean(info?.isVisible));
        setSocialLink(info?.socialLink || []);
        setMenuImages(info?.menuPhotos || []);
        setThirdPartyDelivery(info?.thirdPartyLink || []);
      }
      setLoading(false);
    };
    getInfo();
  }, [toggle]);

  return (
    <RestroXConnectContext.Provider
      value={{
        coverImg,
        basicInfo,
        contact,
        profileImg,
        isNewProfile,
        initialData,
        imageInfo,
        setting,
        toggle,
        socialLink,
        menuImages,
        thirdPartyDelivery,
        loading,
        visibility,
        specialDish,
        categories,
        popularDish,
        logo,
        gallery,
        setVisibility,
        setProfileImage,
        setCoverImg,
        setBasicInfo,
        setContact,
        setIsNewProfile,
        setImageInfo,
        setSetting,
        handleConnectStatus,
        setToggle,
        setSocialLink,
        setMenuImages,
        setThirdPartyDelivery,
        setCategories,
        handleRedirect,
        setPopularDish,
        setGallery,
        setSpecialDish,
        handleConnectVisibility,
        setLogo
      }}
    >
      {children}
    </RestroXConnectContext.Provider>
  );
};

export { RestroXConnectContext, RestroXConnectProvider };
