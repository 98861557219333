import axios from "axios";

const url = process.env.REACT_APP_SERVER_URL;

const instance = axios.create({
  baseURL: url
});

instance.defaults.headers = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  "X-Frame-Options": "DENY", // or set your desired value
  Expires: "0"
};
instance.personalImageUrl = url;

export default instance;
