import { useReducer, useEffect } from "react";

const initialState = {
  data: [],
  totalPage: 1,
  dataCount: 0,
  page: 1,
  refetch: false,
  limit: 25,
  isLoading: true,
  otherParams: undefined,
};

const initialMutation = {
  createMutation: false,
  updateMutation: false,
  deleteMutation: false,
};

function reducerDetails(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };

    case "reset":
      return initialState;

    default:
      throw new Error("Invalid dispatch method.");
  }
}

function reducerMutation(state, action) {
  switch (action.type) {
    case "update":
      return { ...state, ...action.payload };

    case "reset":
      return initialMutation;

    default:
      throw new Error("Invalid dispatch method.");
  }
}

function usePagination({
  fetchMethod,
  pageNumber = 1,
  limit = 25,
  otherParams = undefined,
  isInitialAutoFetch = true,
}) {
  const [details, dispatchDetails] = useReducer(reducerDetails, initialState);
  const [mutation, dispatchMutation] = useReducer(
    reducerMutation,
    initialMutation,
  );

  const fetchData = async () => {
    const responseData = await fetchMethod({
      page: details.page,
      count: details.limit,
      ...details.otherParams,
    });

    if (!responseData) {
      dispatchDetails({
        type: "update",
        payload: {
          isLoading: false,
          refetch: false,
        },
      });

      dispatchMutation({ type: "reset" });
      return;
    }

    // Make the details state to refetch data if the current page has no data
    if (responseData.page !== 1 && !responseData.docs.length) {
      dispatchDetails({
        type: "update",
        payload: {
          page: details.page - 1,
          refetch: true,
        },
      });

      return;
    }

    dispatchDetails({
      type: "update",
      payload: {
        data: responseData.docs,
        totalPage: responseData.totalPages,
        dataCount: responseData?.totalCount,
        isLoading: false,
        refetch: false,
      },
    });

    dispatchMutation({ type: "reset" });
  };

  useEffect(() => {
    if (!isInitialAutoFetch) return;
    dispatchDetails({
      type: "update",
      payload: {
        page: pageNumber || details.page,
        otherParams,
        limit,
        refetch: true,
      },
    });
  }, [isInitialAutoFetch]);

  useEffect(() => {
    if (details.refetch) fetchData();
  }, [details]);

  useEffect(() => {
    if (mutation.createMutation) {
      dispatchDetails({
        type: "update",
        payload: {
          page: 1,
          isLoading: true,
          refetch: true,
        },
      });
    } else if (mutation.updateMutation) {
      dispatchDetails({
        type: "update",
        payload: {
          refetch: true,
        },
      });
    } else if (mutation.deleteMutation) {
      dispatchDetails({
        type: "update",
        payload: {
          refetch: true,
        },
      });
    }
  }, [mutation]);

  const setCurrentPage = (pageNumber) => {
    if (
      !pageNumber ||
      details.page === pageNumber ||
      pageNumber < 1 ||
      pageNumber > details.totalPage
    ) {
      return;
    }

    dispatchDetails({
      type: "update",
      payload: {
        isLoading: true,
        page: pageNumber,
        refetch: true,
      },
    });
  };

  const setCreateMutation = () => {
    dispatchMutation({
      type: "update",
      payload: {
        createMutation: true,
      },
    });
  };

  const setUpdateMutation = () => {
    dispatchMutation({
      type: "update",
      payload: {
        updateMutation: true,
      },
    });
  };

  const setDeleteMutation = () => {
    dispatchMutation({
      type: "update",
      payload: {
        deleteMutation: true,
      },
    });
  };

  const setLimit = (limit = 25) => {
    dispatchDetails({
      type: "update",
      payload: {
        limit,
        page: 1,
        refetch: true,
      },
    });
  };

  const setOtherParams = (data) => {
    dispatchDetails({
      type: "update",
      payload: {
        page: 1,
        refetch: true,
        otherParams: { ...otherParams, ...details.otherParams, ...data },
      },
    });
  };

  const resetStates = () => {
    dispatchDetails({
      type: "reset",
    });

    dispatchMutation({
      type: "reset",
    });
  };

  return {
    details,
    mutation,
    setCurrentPage,
    setCreateMutation,
    setUpdateMutation,
    setDeleteMutation,
    setLimit,
    setOtherParams,
    resetStates,
  };
}

export default usePagination;
