import { getApiResponse } from "@apicall";

const getNotifications = async () => {
  const result = await getApiResponse({
    url: `/employee/notifications/?isRead=false`,
    myVersion: "2.0",
    version: "v3",
    method: "get",
    isAuthenticated: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

const updateNotificationApi = async (id) => {
  const result = await getApiResponse({
    url: `/employee/notifications/${id}/mark-read`,
    myVersion: "2.0",
    version: "v3",
    method: "patch",
    isAuthenticated: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

export { getNotifications, updateNotificationApi };
