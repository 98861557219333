import React, { useState } from "react";

const SwitchRestaurantContext = React.createContext({});

const SwitchRestaurantProvider = ({ children }) => {
  const [restaurantSwitched, setRestaurantSwitched] = useState(false);
  const [updateApp, setUpdateApp] = useState(false);
  const [data, setData] = useState({ name: "", image: "" });

  return (
    <SwitchRestaurantContext.Provider
      value={{
        restaurantSwitched,
        data,
        updateApp,
        setRestaurantSwitched,
        setData,
        setUpdateApp
      }}
    >
      {children}
    </SwitchRestaurantContext.Provider>
  );
};

export { SwitchRestaurantContext, SwitchRestaurantProvider };
