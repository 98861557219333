import { getApiResponse } from "@apicall";

export const getDishImageApi = async (query = "", page = 1) => {
  const totalRestaurantResp = await getApiResponse({
    url: `/admin/photoLibrary/?page=${page}&${query}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: false,
    method: "get",
    type: "application/json"
  });

  return totalRestaurantResp;
};

export const getCategoryImageApi = async (query = "", page = 1) => {
  const totalRestaurantResp = await getApiResponse({
    url: `/admin/photoLibrary/?isCategory=true&page=${page}&${query}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: false,
    method: "get",
    type: "application/json"
  });

  return totalRestaurantResp;
};
