import { useState, useEffect } from "react";
import { getCashBookCategory } from "@apicall/services/account/cashFlow/category";

export const useCategoryInfo = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const getAllData = async () => {
      const response = await getCashBookCategory();
      if (response) {
        const normalizedCategory = Array.from(response || [], (category) => {
          return {
            label: category.name,
            value: category._id,
            id: category._id,
            name: category.name
          };
        });
        setCategories(normalizedCategory || []);
      }
    };
    getAllData();
  }, []);

  return { categories };
};

export default useCategoryInfo;
