import React, { useState, useEffect } from "react";
import {
  getDishImageApi,
  getCategoryImageApi
} from "@apicall/services/photoLibrary";
import { useDebounce } from "use-debounce";

const usePhotoLibrary = () => {
  const [query, setQuery] = useState("");
  const [dishPhotos, setDishPhotos] = useState([]);
  const [searchKey] = useDebounce(query, 500);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchKey) {
      setLoading(true);
      const getPhotoLibrary = async () => {
        let q = "";
        if (query) {
          q = `query=${searchKey}&count=3`;
        }
        const response = await getDishImageApi(q);
        if (response) {
          setDishPhotos(response?.data?.photoLibraries?.docs);
        }
        setLoading(false);
      };
      getPhotoLibrary();
    }
  }, [searchKey]);

  return { dishPhotos, query, setQuery, loading };
};
const useCategoryPhotoLibrary = () => {
  const [query, setQuery] = useState("");
  const [catPhotos, setCatPhotos] = useState([]);
  const [searchKey] = useDebounce(query, 500);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchKey) {
      setLoading(true);

      const getCategoryPhotoLibrary = async () => {
        let q = "";
        if (query) {
          q = `query=${searchKey}&count=3`;
        }
        const response = await getCategoryImageApi(q);
        if (response) {
          setCatPhotos(response?.data?.photoLibraries?.docs);
        }
        setLoading(false);
      };
      getCategoryPhotoLibrary();
    }
  }, [searchKey]);

  return { catPhotos, query, setQuery, loading };
};

export { usePhotoLibrary, useCategoryPhotoLibrary };
