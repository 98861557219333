import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import getRestaurantInfo from "@src/apicall/services/restaurantDetail";

const SubscriptionContext = React.createContext({});

const SubscriptionProvider = ({ children }) => {
  const history = useHistory();
  const userData = useSelector((state) => state?.userDataSlice?.userData);

  const [open, setOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [restaurantInfo, setRestaurantInfo] = useState({});
  const [selectedPlan, setSelectedPlan] = useState("Basic");
  const [plan, setPlan] = useState("Yearly");
  const [availablePlan, setAvailablePlan] = useState({
    type: "basic",
    tableSpace: "10",
    limitedDishes: "Up to 100",
    customer: "50",
    other: { user: "2" }
  });
  const [upModal, setUpModal] = useState(false);

  const navigateToSub = () => {
    history.push("/subscription");
  };

  useEffect(() => {
    if (!userData?.activeRestaurant?._id) return;
    if (Object.keys(restaurantInfo)?.length <= 0) {
      const getInfo = async () => {
        const response = await getRestaurantInfo();
        if (response) {
          setRestaurantInfo(response?.restauarantDetails || {});
        }
      };
      getInfo();
    }
  }, []);

  useEffect(() => {
    if (!userData?.activeRestaurant?._id) return;
    if (toggle) {
      const getInfo = async () => {
        const response = await getRestaurantInfo();
        if (response) {
          setRestaurantInfo(response?.restauarantDetails || {});
        }
        setToggle(false);
      };
      getInfo();
    }
  }, [toggle]);

  return (
    <SubscriptionContext.Provider
      value={{
        open,
        plan,
        toggle,
        detailOpen,
        selectedPlan,
        availablePlan,
        restaurantInfo,
        upModal,
        setUpModal,
        setOpen,
        setToggle,
        setPlan,
        setAvailablePlan,
        setSelectedPlan,
        setDetailOpen,
        navigateToSub
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionContext, SubscriptionProvider };
