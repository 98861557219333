import React from "react";
import { Label, Input } from "reactstrap";
import Proptypes from "prop-types";

const limitOptions = [25, 50, 75, 100];

export const Limit = ({ setLimit, limit }) => {
  return (
    <div
      style={{ float: "right" }}
      className="d-flex align-items-center flex-direction-column"
    >
      <div
        style={{ width: "170px" }}
        className="d-flex align-items-center flex-direction-column"
      >
        <Label for="sort-select">show</Label>
        <Input
          className="dataTable-select ms-1 me-1"
          type="select"
          id="sort-select"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        >
          {limitOptions.map((item, index) => (
            <option value={item} key={index}>
              {item}
            </option>
          ))}
        </Input>
        <Label for="sort-select">entries</Label>
      </div>
    </div>
  );
};

Limit.propTypes = {
  setLimit: Proptypes.func,
  limit: Proptypes.number
};
