import React, { useState, useEffect } from "react";
import changeItemStatusApi from "@apicall/services/pos/changeitemStatusApi";

const KOTContext = React.createContext({});

const KOTProvider = ({ children }) => {
  const [selectedOrder, setSelectedOrder] = useState({});
  const [open, setOpen] = useState(false);
  const [statusChanged, setStatusChanged] = useState({
    state: false,
    status: ""
  });
  const [orderId, setOrderId] = useState("");

  const handleClose = () => {
    setSelectedOrder({});
    setOpen(false);
  };

  const changeItemStatus = async (value) => {
    if (!value) return;
    if (!selectedOrder?._id) return;
    const changeItemStatusResponse = await changeItemStatusApi({
      orderId,
      itemId: selectedOrder?._id,
      status: value
    });

    if (changeItemStatusResponse?.status === 200) {
      setOpen(false);
      setStatusChanged({ state: true, status: value });
    }
  };

  return (
    <KOTContext.Provider
      value={{
        setSelectedOrder,
        selectedOrder,
        open,
        statusChanged,
        setOpen,
        handleClose,
        changeItemStatus,
        setStatusChanged,
        setOrderId
      }}
    >
      {children}
    </KOTContext.Provider>
  );
};

export { KOTContext, KOTProvider };
