import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Upload, Trash2, Repeat } from "react-feather";
import { useSkin } from "@hooks";
import { ImageCropModal } from "../reusable/image-crop-modal";
import { readFile } from "@utils";
import "./styles.scss";

export const ImageUpload = ({
  image,
  setImage,
  defaultImg = undefined,
  deleteImage,
  deleteFor,
  size = "square"
}) => {
  const { skin } = useSkin();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectImage, setSelectImage] = useState();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: ["image/png", "image/jpg", "image/jpeg"].join(","),
    disabled: image?.length > 1 ? true : false,
    onDrop: async (acceptedFiles) => {
      const image = [...acceptedFiles.map((file) => Object.assign(file))];
      const imageDataUrl = await readFile(image[0]);
      if (imageDataUrl) {
        setSelectImage(imageDataUrl);
        setModalOpen(true);
      }
    }
  });

  const cancelModal = () => {
    setModalOpen(false);
    setSelectImage(null);
    setImage([]);
  };

  const handleImageRemove = () => {
    setImage([]);
    setSelectImage();
    if (defaultImg && deleteFor) {
      deleteImage(deleteFor);
    }
  };

  return (
    <>
      {image?.length || defaultImg ? (
        <div
          className={`upload-select-payment-qr ${
            skin === "light"
              ? "image-upload-select-light"
              : "image-upload-select-dark"
          }`}
        >
          <div>
            <img
              src={image?.length ? URL.createObjectURL(image[0]) : defaultImg}
              alt="dish category"
            />
          </div>
          <div>
            <p className="upload-remove" onClick={(e) => handleImageRemove()}>
              <Trash2 size={16} /> Remove
            </p>
            <p
              className="upload-replace"
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} />
              <Repeat size={16} /> Replace Photo
            </p>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps({ className: "dropzone" })}
          className={
            skin === "light" ? "image-upload-light" : "image-upload-dark"
          }
        >
          <input {...getInputProps()} />
          <Upload />
          <p
            style={{ fontWeight: "500", margin: 0, padding: 0 }}
            className="m-0 p-0"
          >
            Upload Image
          </p>
          <p>
            <span>Select image </span>from your device.
          </p>
        </div>
      )}
      <ImageCropModal
        image={selectImage}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        setCroppedImage={(data) => setImage([data])}
        cancelModal={cancelModal}
        imageSize={size}
      />
    </>
  );
};
