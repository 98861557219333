import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSkin } from "@hooks/useSkin";

export const MenuSkeleton = () => {
  const { skin } = useSkin();

  return (
    <SkeletonTheme
      baseColor={skin === "light" ? "" : "#202020"}
      highlightColor={skin === "light" ? "" : "#444"}
    >
      <div className="p-1 m-1 position-relative rounded border">
        <div className="d-flex justify-content-center">
          <Skeleton height={100} width={100} />
        </div>
        <p className="mt-1 mb-0 text-center">
          <Skeleton width={120} />
        </p>
        <p className="mb-0 text-12 text-center">
          <Skeleton width={80} />
        </p>
        <p className="mb-0 text-end">
          <Skeleton height={20} width={40} />
        </p>
      </div>
    </SkeletonTheme>
  );
};
