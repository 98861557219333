import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import getProfileInfo from "@apicall/services/profileInfo";
import getBuilId from "@apicall/services/buidId";
import { refetchUserData } from "@store/userDataSlice/userDataSlice";

const ProfileContext = React.createContext({});

const ProfileProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [countryInfo, setCountryInfo] = useState({});
  const userData = useSelector((state) => state?.userDataSlice?.userData);

  const [newUpdate, setNewUpdate] = useState(false);
  const [buildId, setBuildId] = useState("");
  // const [cropModalOpen, setCropModalOpen] = useState(false);

  useEffect(() => {
    const getLatestBuildId = async () => {
      const response = await getBuilId();
      if (response) {
        setBuildId(response?.githubCommitSha || "");
        const prevBuild = localStorage.getItem("githubCommitSha");
        if (prevBuild) {
          if (response?.githubCommitSha !== prevBuild) {
            setNewUpdate(true);
          } else {
            setNewUpdate(false);
          }
        } else {
          localStorage.setItem(
            "githubCommitSha",
            response?.githubCommitSha || ""
          );
        }
      }
    };
    if (userData) {
      getLatestBuildId();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(profileInfo)?.length <= 0 && userData) {
      const getInfo = async () => {
        const response = await getProfileInfo();
        if (response) {
          setProfileInfo(response?.userData || {});
          dispatch(refetchUserData());
        }
      };
      getInfo();
    }
  }, []);

  useEffect(() => {
    if (toggle) {
      const getInfo = async () => {
        const response = await getProfileInfo();
        if (response) {
          setProfileInfo(response?.userData || {});
          dispatch(refetchUserData());
        }
        setToggle(false);
      };
      getInfo();
    }
  }, [toggle]);

  useEffect(() => {
    if (Object.keys(countryInfo)?.length <= 0) {
      const getCountryInfo = async () => {
        try {
          const response = await axios.get("https://ipwho.is");
          if (response) {
            setCountryInfo({
              country_name: response?.data?.country,
              ...response?.data
            });
          } else {
            setCountryInfo({
              country_name: "USA",
              calling_code: "1",
              country_code: "US"
            });
          }
        } catch (e) {
          setCountryInfo({
            country_name: "USA",
            calling_code: "1",
            country_code: "US"
          });
        }
      };
      getCountryInfo();
    }
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        toggle,
        profileInfo,
        countryInfo,
        newUpdate,
        buildId,
        // cropModalOpen,
        setProfileInfo,
        setToggle,
        setNewUpdate,
        setBuildId
        // setCropModalOpen
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileContext, ProfileProvider };
