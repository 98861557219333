import { useState, useEffect } from "react";
import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SERVER_URL || "https://api.restrox.co";

export const useOrderCount = () => {
  const [socket, setSocket] = useState();
  const [orderCount, setOrderCount] = useState(0);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("token");

  useEffect(() => {
    const count = localStorage.getItem("orderCount");
    setOrderCount(count || 0);
  }, [orderCount]);

  useEffect(() => {
    const initialSocket = io(SOCKET_URL, {
      auth: {
        user_id: userData?._id || "",
        token
      },
      transports: ["websocket"]
    });
    setSocket(initialSocket);
    return () => {
      initialSocket.close();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;
    socket.on("order_refetch", (data) => {
      if (data?.restaurantId === userData?.activeRestaurant?._id) {
        const _count = localStorage.getItem("orderCount") || 0;
        if (data?.type === "order") {
          localStorage.setItem("orderCount", parseInt(_count) + 1);
          setOrderCount(parseInt(_count) + 1);
        } else {
          localStorage.setItem("orderCount", parseInt(_count) - 1);
          setOrderCount(parseInt(_count) - 1);
        }
      }
    });
  }, [socket]);

  return { orderCount, setOrderCount };
};

export default useOrderCount;
