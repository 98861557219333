import { useState, useEffect } from "react";
import { io } from "socket.io-client";

const SOCKET_URL = process.env.REACT_APP_SERVER_URL || "https://api.restrox.co";

const useSocket = () => {
  const [socket, setSocket] = useState();
  const [isSocketChanged, setIsSocketChanged] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const token = localStorage.getItem("token");

  useEffect(() => {
    const initialSocket = io(SOCKET_URL, {
      auth: {
        user_id: userData._id,
        token
      },
      transports: ["websocket"]
    });

    setSocket(initialSocket);

    return () => {
      initialSocket.close();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("order_refetch", (data) => {
      if (data.restaurantId === userData?.activeRestaurant?._id) {
        setIsSocketChanged(true);
      }
    });
  }, [socket]);

  return { isSocketChanged, setIsSocketChanged };
};

export default useSocket;
