import { useEffect, useState } from "react";
import fetchAllRolesApi from "@apicall/services/staff/fetchAllRoles";
import fetchPositionListApi from "@apicall/services/staff/fetchPositionList";

export const useStaff = () => {
  const [staffRoles, setStaffRoles] = useState([]);
  const [staffPositions, setStaffPositions] = useState([]);
  useEffect(() => {
    const getStaffData = async () => {
      const [roles, positions] = await Promise.all([
        fetchAllRolesApi(),
        fetchPositionListApi()
      ]);

      if (roles?.roles) {
        const newRoles = roles?.roles.reduce((data, current) => {
          if (current?.name) {
            data.push({
              id: current?._id,
              name: current?.name,
              label: current?.name,
              value: current?._id,
              _id: current?._id
            });
          }
          return data;
        }, []);
        setStaffRoles(newRoles);
      }

      if (positions?.positions) {
        const newPosition = [];
        positions?.positions.map((positionData) => {
          newPosition.push({
            label: positionData,
            value: positionData,
            id: positionData,
            name: positionData,
            _id: positionData
          });
        });
        setStaffPositions(newPosition);
      }
    };
    getStaffData();
  }, []);
  return { staffRoles, staffPositions };
};

export default useStaff;
