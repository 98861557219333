import { createContext, useEffect, useState } from "react";
import { getDayBookReportApi } from "@apicall/services/account/daybook";

export const DayBookCloseContext = createContext(null);

export const getCashState = (amount) => {
  if (amount > 0) {
    return "cash-plus";
  }
  if (amount === 0) {
    return "cash-equal";
  }
  if (amount < 0) {
    return "cash-danger";
  }
};

function DayBookCloseConsumer({ children }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [tab, setTab] = useState("summary");
  const [domination, setDomination] = useState({
    1000: "",
    500: "",
    100: "",
    50: "",
    20: "",
    10: "",
    5: "",
    2: "",
    1: ""
  });
  const [newOpeningCash, setNewOpeningCash] = useState("");
  const [cashDifference, setCashDifference] = useState("");
  const [total, setTotal] = useState("");
  const [cashState, setCashState] = useState("");
  const [balanceType, setBalanceType] = useState("");
  const [remarks, setRemarks] = useState("");
  const [_date, setDate] = useState();
  const [loading, setLoading] = useState(true);
  const [toggleData, setToggleData] = useState(false);
  const [report, setReport] = useState({});
  const handleClose = () => {
    setModalOpen(false);
  };

  const clearState = () => {
    setRemarks("");
    setReport({});
    setDate();
    setCashState("");
    setCashDifference("");
    setNewOpeningCash("");
    setBalanceType("");
    setToggleData(false);
    setDomination({
      1000: "",
      500: "",
      100: "",
      50: "",
      20: "",
      10: "",
      5: "",
      2: "",
      1: ""
    });
  };
  const handleSelect = (type) => {
    if (type === balanceType) {
      setBalanceType("");
    } else {
      setBalanceType(type);
    }
  };
  useEffect(() => {
    const getDayReport = async () => {
      setToggleData(false);
      setLoading(true);
      const date = new Date();
      const response = await getDayBookReportApi({ endDate: date });
      if (response) {
        setReport(response?.report || {});
      }
      setLoading(false);
    };
    getDayReport();
  }, [toggle, toggleData]);

  useEffect(() => {
    let diff = 0;
    const newAmount = newOpeningCash || total || 0;

    if (newAmount && report?.cashInCounter) {
      if (Number(report?.cashInCounter || 0) < 0) {
        diff = Number(newAmount) + Number(report?.cashInCounter?.toFixed(2));
      } else {
        diff = Number(newAmount) - Number(report?.cashInCounter.toFixed(2));
      }
      setCashDifference(diff);
      setCashState(getCashState(Number(diff || 0)));
    } else {
      setBalanceType("");
      setCashDifference("");
      setCashState("");
    }
  }, [total, newOpeningCash]);
  return (
    <DayBookCloseContext.Provider
      value={{
        modalOpen,
        toggle,
        tab,
        domination,
        newOpeningCash,
        cashDifference,
        total,
        cashState,
        balanceType,
        remarks,
        _date,
        loading,
        toggleData,
        report,
        setModalOpen,
        setToggle,
        setTab,
        setDomination,
        setNewOpeningCash,
        setCashDifference,
        setTotal,
        setCashState,
        setBalanceType,
        setRemarks,
        setDate,
        setLoading,
        setToggleData,
        setReport,
        handleClose,
        clearState,
        handleSelect
      }}
    >
      {children}
    </DayBookCloseContext.Provider>
  );
}
export { DayBookCloseConsumer };
