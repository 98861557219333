import { getApiResponse } from "@apicall";

const changeItemStatusApi = async ({
  orderId = null,
  status = null,
  itemId = null,
}) => {
  const result = await getApiResponse({
    url: `/order/${orderId}/status`,
    myVersion: "2.0",
    version: "v2",
    method: "patch",
    isAuthenticated: true,
    ofRestaurant: true,
    type: "application/json",
    data: {
      status,
      itemIds: [itemId],
    },
  });
  if (result.APIFailed) return null;
  return result;
};

export default changeItemStatusApi;
