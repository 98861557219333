import { createSlice } from "@reduxjs/toolkit";

const getInitialUserData = () => {
  try {
    return JSON.parse(localStorage.getItem("userData"));
  } catch {
    return null;
  }
};

const initialState = {
  userData: getInitialUserData(),
};

export const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    refetchUserData: (state) => {
      state.userData = getInitialUserData();
    },
  },
});

export const { refetchUserData } = userDataSlice.actions;
export default userDataSlice.reducer;
