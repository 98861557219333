import { getApiResponse } from "@apicall";

const getBuilId = async () => {
  const response = await getApiResponse({
    url: "/employee/githubSha",
    myVersion: "2.0",
    version: "v2"
  });

  if (response.APIFailed) return null;

  return response.data;
};

export default getBuilId;
