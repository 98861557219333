import { getApiResponse } from "@apicall";

const createCashBookCategory = async (data) => {
  const result = await getApiResponse({
    url: `/cash-flows/category`,
    myVersion: "2.0",
    version: "v3",
    ofRestaurant: true,
    method: "post",
    isAuthenticated: true,
    data,
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

const getCashBookCategory = async (data) => {
  const result = await getApiResponse({
    url: `/cash-flows/category`,
    myVersion: "2.0",
    version: "v3",
    ofRestaurant: true,
    method: "get",
    isAuthenticated: true,
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export { createCashBookCategory, getCashBookCategory };
