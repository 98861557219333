import { useEffect } from "react";
import { useSkin } from "@hooks/useSkin";
import { createTheme } from "react-data-table-component";

function useThemeCustomizer({ solarized }) {
  const { skin } = useSkin();
  useEffect(() => {
    if (skin === "dark") {
      createTheme(
        solarized,
        {
          text: {
            primary: "#ffff",
          },
          background: {
            default: "transparent",
          },
          divider: {
            default: "#373737",
          },
        },
        "dark",
      );
    } else {
      createTheme(
        solarized,
        {
          text: {
            primary: "black",
          },
          background: {
            default: "transparent",
          },
          divider: {
            default: "#e0e0e0",
          },
        },
        "light",
      );
    }
  }, [skin]);
}

export default useThemeCustomizer;
