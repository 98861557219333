import { getApiResponse } from "@apicall";

const signUpApi = async (_data) => {
  const result = await getApiResponse({
    url: `/employee/signup`,
    myVersion: "2.0",
    version: "v2",
    ofRestaurant: false,
    method: "post",
    isAuthenticated: true,
    type: "application/json",
    data: _data,
  });
  if (result.APIFailed) return null;
  return result;
};

export const signUpPhoneApi = async (_data) => {
  const result = await getApiResponse({
    url: `/employee/phone-verification/initiate`,
    myVersion: "2.0",
    version: "v3",
    ofRestaurant: false,
    method: "post",
    isAuthenticated: true,
    type: "application/json",
    data: _data,
  });
  if (result.APIFailed) return null;
  return result;
};

export default signUpApi;
