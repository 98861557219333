import React, { forwardRef, useState } from "react";
import { Input, Label } from "reactstrap";
import Proptypes from "prop-types";

const RestroXNumberInput = forwardRef((props, ref) => {
  const {
    labelfor,
    label = undefined,
    amount,
    placeholder,
    setAmount,
    ...rest
  } = props;

  const numberHandler = (e) => {
    const value = e.target.value.trim();
    if (!value) {
      setAmount("");
      return;
    }
    if (isNaN(value)) return;
    if (Number(value) < 0) return;
    const regex = /^\d+(\.\d{1,2})?$/;
    if (!regex.test(Number(value))) return;
    setAmount(value);
  };

  return (
    <>
      {label && <Label for={labelfor || ""}>{label || ""}</Label>}
      <Input
        ref={ref}
        type="text"
        placeholder={placeholder}
        value={amount || ""}
        onChange={(e) => numberHandler(e)}
        {...rest}
      />
    </>
  );
});

export { RestroXNumberInput };

RestroXNumberInput.propTypes = {
  labelfor: Proptypes.string,
  label: Proptypes.string,
  amount: Proptypes.string || Proptypes.number,
  placeholder: Proptypes.string,
  setAmount: Proptypes.func
};
