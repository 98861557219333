import { getApiResponse } from "@apicall";

const fetchStaffByFilter = async ({
  position = undefined,
  roles = undefined,
  status = undefined,
  departmentId = undefined
}) => {
  const result = await getApiResponse({
    url: `/staff/filter`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    ofRestaurant: true,
    otherParams: {
      position,
      roles,
      status,
      departmentId
    }
  });
  if (result.APIFailed) return null;
  return result.data;
};

export const getAllStaff = async () => {
  const result = await getApiResponse({
    url: `/staff/?notPending=true`,
    myVersion: "2.0",
    version: "v2",
    isAuthenticated: true,
    ofRestaurant: true
  });
  if (result.APIFailed) return null;
  return result.data;
};

export default fetchStaffByFilter;
