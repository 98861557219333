import { getApiResponse } from "@apicall";

const getProfileInfo = async () => {
  const result = await getApiResponse({
    url: `/restaurant/restaurantInfo`,
    myVersion: "2.0",
    ofRestaurant: false,
    method: "get",
    isAuthenticated: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default getProfileInfo;
