import { useState, useEffect } from "react";

export const useResend = () => {
  const [time, setTime] = useState(60);
  const [resend, setResend] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (time === 0) {
        setResend(true);
      } else {
        setTime(time - 1);
      }
    }, 1000);
    return () => clearTimeout(interval);
  }, [time]);

  return { time, resend, setTime, setResend };
};
