import "@styles/react/libs/toastify/toastify.scss";
import { notifyDishError } from "@atoms";

export const hasVariantOrAddon = (data) => {
  if (
    (typeof data?.addons === "undefined" || data?.addons?.length === 0) &&
    data?.variants?.length === 1 &&
    (data?.variants[0]?.name === data?.itemName ||
      data?.variants[0]?.name === "default")
  ) {
    return false;
  }
  return true;
};

export const calculateAddon = (addon = []) => {
  let totalCost = 0;
  addon?.forEach((item) => {
    totalCost += parseFloat((item?.price || 0).toString());
  });

  return totalCost;
};

export const isDishAlreadyAdded = (dishData, orderList) => {
  const dishArray = [...orderList].reverse();
  const dish = dishArray?.find((val) => {
    return val.id === dishData._id;
  });

  return dish;
};

export const calculateAddonWithQty = (addon = [], quantity = 0) => {
  let totalCost = 0;
  addon?.forEach((item) => {
    totalCost +=
      parseFloat((item?.price || 0).toString()) *
      parseFloat(quantity.toString());
  });

  return totalCost;
};

export const isDefaultVariant = (dishData) => {
  if (
    dishData?.variants?.length === 1 &&
    (dishData?.variants[0]?.name === dishData?.itemName ||
      dishData?.variants[0]?.name === "default")
  ) {
    return true;
  }
  return false;
};

export const isOrderDefaultVariant = (dishData) => {
  if (
    dishData?.variantName === dishData?.name ||
    dishData?.variantName === "default"
  ) {
    return true;
  }
  return false;
};

export const isDataValid = (data) => {
  if (!data?.variant?.name) {
    notifyDishError("Please select variant or addons!");
    return false;
  }
  if (data.quantity === 0) {
    notifyDishError("Please enter quantity!");
    return false;
  }
  return true;
};

export const isOrderVariant = (item) => {
  if (item?.reference?.item?.variantDetail?.name?.trim()?.length) {
    if (
      item?.name?.toLowerCase() !==
        item?.reference?.item?.variantDetail?.name?.toLowerCase() &&
      item?.reference?.item?.variantDetail?.name !== "default"
    ) {
      return true;
    }
  } else {
    return false;
  }
};

export const billCheckoutVariant = (dishName, variantName) => {
  if (variantName && variantName !== "default") {
    if (dishName?.toLowerCase() !== variantName?.toLowerCase()) {
      return true;
    }
    return false;
  }
  return false;
};

export const getSortedVariant = (variants = []) => {
  const variant = [...variants];
  const sortedVariant = variant.sort((a, b) => {
    return (
      (a.discountedPrice !== undefined && a.discountedPrice !== null
        ? a.discountedPrice
        : a.price) -
      (b.discountedPrice !== undefined && b.discountedPrice !== null
        ? b.discountedPrice
        : b.price)
    );
  });

  const smallVariant = sortedVariant[0];
  const largeVaraint = sortedVariant[sortedVariant?.length - 1];

  return { sortedVariant, smallVariant, largeVaraint };
};

export const getVariantPrice = (variant) => {
  if (
    variant?.discountedPrice !== undefined &&
    variant?.discountedPrice !== null
  ) {
    return variant?.discountedPrice || 0;
  }
  return variant?.price || 0;
};

export const getActualPrice = (price) => {
  if (price !== undefined && price !== null) {
    return price || 0;
  }
  return price || 0;
};

export const orderAdded = (dishData, orderList) => {
  const dishArray = [...orderList];
  const dish = dishArray?.find((val) => {
    return val?.id === dishData?._id;
  });
  if (dish?.id) {
    return true;
  }
  return false;
};

export function handleMergeItems(items) {
  return items?.reduce((accumulator, currentItem) => {
    const existingItem = accumulator.find(
      (item) =>
        item.name === currentItem.name &&
        item.variantName === currentItem.variantName
    );
    if (existingItem) {
      existingItem.quantity += currentItem.quantity;
    } else {
      accumulator.push({ ...currentItem });
    }
    return accumulator;
  }, []);
}
