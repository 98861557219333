import React, { createContext, useEffect, useState } from "react";
import fetchCustomerApi from "@apicall/services/customer/fetchCustomerApi";

const CustomerContext = React.createContext({});

const CustomerProvider = ({ children }) => {
  const [customerSelected, setCustomerSelected] = useState({});
  const [customerOptionsData, setCustomersOptionsData] = useState([]);
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [customerRemarks, setCustomerRemarks] = useState();

  const handleCustomerSelect = (option) => {
    setCustomerSelected(option);
  };

  useEffect(() => {
    const getCustomersData = async () => {
      const customerDataResponse = await fetchCustomerApi();
      const customers = [];
      customerDataResponse?.customers?.map((data) => {
        customers.push({
          label: data.name,
          value: data._id,
          email: data.email,
          address: data.address ? data.address : "",
          contact: data.contact ? data.contact : ""
        });
      });

      setCustomersOptionsData(customers);
    };
    getCustomersData();
  }, []);
  return (
    <CustomerContext.Provider
      value={{
        customerSelected,
        customerOptionsData,
        customerAddress,
        customerName,
        customerNumber,
        customerRemarks,
        handleCustomerSelect,
        setCustomerSelected,
        setCustomersOptionsData,
        setCustomerAddress,
        setCustomerName,
        setCustomerNumber,
        setCustomerRemarks
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export { CustomerContext, CustomerProvider };
