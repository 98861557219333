import { getApiResponse } from "@apicall";

const fetchDepartmentApi = async ({
  page = 1,
  query = undefined,
  sort = undefined,
  count = 25,
}) => {
  const result = await getApiResponse({
    url: `/department/`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    ofRestaurant: true,
    otherParams: {
      page,
      test: "check",
      query,
      sort,
      count,
    },
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default fetchDepartmentApi;
