import { getApiResponse } from "@apicall";

const switchTable = async (id, data) => {
  const result = await getApiResponse({
    url: `/table/${id}/order`,
    myVersion: "2.0",
    ofRestaurant: true,
    method: "patch",
    version: "v2",
    isAuthenticated: true,
    type: "application/json",
    data
  });
  if (result.APIFailed) return null;
  return result.data;
};

const switchCustomerToTable = async (id, data) => {
  const result = await getApiResponse({
    url: `/order/customer/${id}`,
    myVersion: "2.0",
    ofRestaurant: true,
    method: "patch",
    version: "v2",
    isAuthenticated: true,
    type: "application/json",
    data
  });
  if (result.APIFailed) return null;
  return result.data;
};

const switchStaffOrder = async (id, data) => {
  const result = await getApiResponse({
    url: `/order/staff/${id}`,
    myVersion: "2.0",
    ofRestaurant: true,
    method: "patch",
    version: "v2",
    isAuthenticated: true,
    type: "application/json",
    data
  });
  if (result.APIFailed) return null;
  return result.data;
};

export { switchTable, switchCustomerToTable, switchStaffOrder };
