import { getApiResponse } from "@apicall";

const getAllDayBookApi = async ({
  duration,
  staff = [],
  page = 1,
  count = 25
}) => {
  const result = await getApiResponse({
    url: `/cash-flows/daybook`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    type: "application/json",
    method: "get",
    ofRestaurant: true,
    otherParams: {
      startDate: duration.startDate || "",
      endDate: duration.endDate || "",
      staff,
      page,
      count
    }
  });

  if (result.APIFailed) return null;
  return result.data;
};

const getDayBookDetailApi = async (id) => {
  const result = await getApiResponse({
    url: `/cash-flows/daybook/${id}`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    type: "application/json",
    method: "get",
    ofRestaurant: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

const getDayBookReportApi = async ({ endDate }) => {
  const result = await getApiResponse({
    url: `/cash-flows/daybook/report`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    type: "application/json",
    method: "get",
    ofRestaurant: true
    // otherParams: {
    //   endDate: endDate || ""
    // }
  });

  if (result.APIFailed) return null;
  return result.data;
};

const postDayBookReportApi = async ({ payload }) => {
  const result = await getApiResponse({
    url: `/cash-flows/daybook/`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    type: "application/json",
    method: "post",
    ofRestaurant: true,
    data: payload
  });

  if (result.APIFailed) return null;
  return result.data;
};

const deleteDayBookReportApi = async (id) => {
  const result = await getApiResponse({
    url: `/cash-flows/daybook/`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    type: "application/json",
    method: "delete",
    ofRestaurant: true,
    data: { reports: id }
  });

  if (result.APIFailed) return null;
  return result.data;
};

const dayBookBalance = async (data) => {
  const result = await getApiResponse({
    url: `/cash-flows/daybook/opening-balance-setting`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    type: "application/json",
    method: "post",
    ofRestaurant: true,
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export {
  getAllDayBookApi,
  getDayBookDetailApi,
  getDayBookReportApi,
  postDayBookReportApi,
  dayBookBalance,
  deleteDayBookReportApi
};
