import React from "react";
import crownX from "@src/assets/images/logo/crownX.svg";

const BadgeX = ({ text, textColor, icon, bgColor, CustIcon, ...rest }) => {
  return (
    <div
      style={{
        backgroundColor: bgColor ? bgColor : "#f2f4f7",
        padding: "4px 8px",
        borderRadius: "4px",
        marginTop: CustIcon && "4px",
        width: "fit-content",
        display: "flex"
      }}
      {...rest}
    >
      {icon && <img src={crownX} color="#fdb022" width={15} />}
      {CustIcon && <img src={CustIcon} alt="crown" width={15} />}

      <span
        className={`font-semibold  ${(icon || CustIcon) && "ms-1"} `}
        style={{
          color: textColor ? `${textColor}` : "",
          fontSize: "11px"
        }}
      >
        {text}
      </span>
    </div>
  );
};

export default BadgeX;
