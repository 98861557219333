import { getApiResponse } from "@apicall";

const addDishApi = async (data) => {
  const result = await getApiResponse({
    url: `/dish`,
    method: "post",
    isAuthenticated: true,
    type: "multipart/form-data",
    data,
    myVersion: "2.0",
    ofRestaurant: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const addBulkDishApi = async (data) => {
  const result = await getApiResponse({
    url: `/dish/bulkItems`,
    method: "post",
    isAuthenticated: true,
    type: "application/json",
    data,
    myVersion: "2.0",
    version: "v3",
    ofRestaurant: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getDishDetail = async (id) => {
  const result = await getApiResponse({
    url: `/dish/${id}`,
    method: "get",
    isAuthenticated: true,
    ofRestaurant: true,
    myVersion: "2.0"
  });
  if (result.APIFailed) return null;
  return result.data;
};

export default addDishApi;
