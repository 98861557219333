import { Fragment } from "react";
import { Check, X } from "react-feather";
import { toast } from "react-toastify";
import "@styles/react/libs/toastify/toastify.scss";

const styles = {
  backgroundColor: "#00cc00",
  width: "30px",
  height: "30px",
  borderRadius: "50%"
};
const styles2 = {
  backgroundColor: "#ea5455",
  width: "30px",
  height: "30px",
  borderRadius: "50%"
};
const styles1 = {
  display: "flex",
  alignItem: "center",
  paddingTop: "25%",
  marginLeft: "4.5px"
};

const SuccessToast = ({ title, message }) => {
  return (
    <Fragment>
      <div className="toastify-header">
        <div className="title-wrapper">
          <div style={styles}>
            <div style={styles1}>
              <Check size={20} style={{ color: "white" }} />
            </div>
          </div>
          <h6 style={{ color: "#00cc00" }} className="toast-title">
            {title}
          </h6>
        </div>
        <small className="text-muted">Just Now</small>
      </div>
      <div className="toastify-body">
        <span
          role="img"
          aria-label="toast-text"
          style={{ fontSize: "0.94rem" }}
        >
          {message}
        </span>
      </div>
    </Fragment>
  );
};

const WarningToast = ({ message }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <div style={styles2}>
          <div style={styles1}>
            <X size={20} style={{ color: "white", marginTop: "-2px" }} />
          </div>
        </div>
        <h6 className="toast-title">Error!</h6>
      </div>
      <small className="text-muted">Just Now</small>
    </div>
    <div className="toastify-body">
      <span
        role="img"
        aria-label="toast-text"
        style={{ fontSize: "0.94rem" }}
        // className="fw-bold"
      >
        {message}
      </span>
    </div>
  </Fragment>
);

const ErrorToast = ({ title, message }) => (
  <Fragment>
    <div className="toastify-header">
      <div className="title-wrapper">
        <div style={styles2}>
          <div style={styles1}>
            <X size={20} style={{ color: "white", marginTop: "-2px" }} />
          </div>
        </div>
        <h6 className="toast-title">{title}</h6>
      </div>
    </div>
    <div className="toastify-body">
      <span role="img" aria-label="toast-text" style={{ fontSize: "0.94rem" }}>
        {message}
      </span>
    </div>
  </Fragment>
);

export const notifyBottomLeft = (title, message) => {
  toast(<SuccessToast title={title || "Success!"} message={message} />, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true
  });
};

export const notifyError = (title, message) => {
  toast(<ErrorToast title={title} message={message} />, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true
  });
};

export const notifyDishError = (message) => {
  return toast(<WarningToast title={"Error"} message={message} />, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true
  });
};

export const notifyBottomWarning = (title, message) => {
  return toast(<WarningToast title={title} message={message} />, {
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true
  });
};
