import React, { useContext } from "react";
import { ProfileContext } from "@context";

export const IRDMessage = () => {
  const { countryInfo } = useContext(ProfileContext);

  return (
    <>
      {countryInfo?.country === "Nepal" && (
        <div
          style={{
            backgroundColor: "#E0F9EC",
            borderRadius: "8px",
            padding: "10px"
          }}
        >
          If you’re using from Nepal, this software is not for IRD Billing.
          Please use IRD Verified Billing Software. This App is only for
          Website, Delivery Menu, Inventory and other features but not billing.{" "}
          <a href="https://restrox.co/contacts" target="_blank">
            Please Contact Us for IRD approved system.
          </a>
        </div>
      )}
    </>
  );
};
