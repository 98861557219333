import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const usePaymentMethods = () => {
  const paymentMethods = useSelector(
    (state) =>
      state?.userDataSlice?.userData?.activeRestaurant?.paymentMethods || []
  );

  const [paymentMethod, setPaymentMethod] = useState([]);

  useEffect(() => {
    if (paymentMethods?.length) {
      const normalizedPayments = Array.from(paymentMethods || [], (payment) => {
        return { id: payment?._id, name: payment?.name };
      });
      setPaymentMethod(normalizedPayments);
    }
  }, [paymentMethods]);

  return { paymentMethod };
};

export default usePaymentMethods;
