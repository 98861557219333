import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { useSelector } from "react-redux";
import { getNotifications } from "@apicall/services/invitation";
import { notifyBottomLeft } from "@atoms";

const SOCKET_URL = process.env.REACT_APP_SERVER_URL || "https://api.restrox.co";

const useNotification = () => {
  const [socket, setSocket] = useState();
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const userData = useSelector((state) => state?.userDataSlice?.userData);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const getAllNotifications = async () => {
      setLoading(true);
      const response = await getNotifications();
      if (response) {
        setNotifications(response);
      }
      // setToggle(false);
      setLoading(false);
    };
    getAllNotifications();
  }, [toggle]);

  useEffect(() => {
    const initialSocket = io(SOCKET_URL, {
      auth: {
        user_id: userData?._id || "",
        token
      },
      transports: ["websocket"]
    });

    setSocket(initialSocket);

    return () => {
      initialSocket.close();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("invite_notification", (data) => {
      if (data) {
        notifyBottomLeft("Invitation", data?.text || "You have been invited");
        setNotifications((prevData) => [data, ...prevData]);
      }
    });
  }, [socket]);

  return { notifications, loading, userData, setToggle, toggle };
};

export { useNotification };
