import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getProfileInfo from "@apicall/services/profileInfo";
import { refetchUserData } from "@store/userDataSlice/userDataSlice";

const InvoiceSettingContext = React.createContext({});

const InvoiceSettingProvider = ({ children }) => {
  const restaurantInfo = useSelector(
    (state) => state?.userDataSlice?.userData?.activeRestaurant
  );
  const dispatch = useDispatch();
  const [restaurantLogo, setRestaurantLogo] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [invoiceInfo, setInvoiceInfo] = useState({
    legalName: "",
    taxNumber: "",
    address: "",
    invoiceName: { value: "", label: "" },
    phoneNumber: "",
    defaultLogo: ""
  });
  const [footerInfo, setFooterInfo] = useState({ heading: "", remarks: "" });
  const [initialData, setInitialData] = useState({});
  const [initialFooterData, setInitialFooterData] = useState({});
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    if (restaurantInfo) {
      setInvoiceInfo({
        legalName:
          restaurantInfo?.invoiceSettings?.header?.restaurantLegalName ||
          restaurantInfo?.name ||
          "",
        taxNumber: restaurantInfo?.invoiceSettings?.header?.taxNumber || "",
        address:
          restaurantInfo?.invoiceSettings?.header?.address ||
          restaurantInfo?.location?.address ||
          "",
        phoneNumber:
          restaurantInfo?.invoiceSettings?.header?.phoneNumber ||
          restaurantInfo?.contact ||
          "",
        invoiceName: {
          value: restaurantInfo?.invoiceSettings?.header?.invoiceName || "",
          label: restaurantInfo?.invoiceSettings?.header?.invoiceName || ""
        },
        defaultLogo:
          restaurantInfo?.invoiceSettings?.header?.restaurantLogo || ""
      });
      setFooterInfo({
        heading: restaurantInfo?.invoiceSettings?.footer?.heading || "",
        remarks: restaurantInfo?.invoiceSettings?.footer?.remarks || ""
      });
      setPhoneNumber(
        restaurantInfo?.invoiceSettings?.header?.phoneNumber ||
          restaurantInfo?.contact ||
          ""
      );

      setInitialData({
        legalName:
          restaurantInfo?.invoiceSettings?.header?.restaurantLegalName ||
          restaurantInfo?.name ||
          "",
        taxNumber: restaurantInfo?.invoiceSettings?.header?.taxNumber || "",
        address:
          restaurantInfo?.invoiceSettings?.header?.address ||
          restaurantInfo?.location?.address ||
          "",
        phoneNumber:
          restaurantInfo?.invoiceSettings?.header?.phoneNumber ||
          restaurantInfo?.contact ||
          "",
        invoiceName: {
          value: restaurantInfo?.invoiceSettings?.header?.invoiceName || "",
          label: restaurantInfo?.invoiceSettings?.header?.invoiceName || ""
        },
        defaultLogo:
          restaurantInfo?.invoiceSettings?.header?.restaurantLogo || ""
      });

      setInitialFooterData({
        heading: restaurantInfo?.invoiceSettings?.footer?.heading || "",
        remarks: restaurantInfo?.invoiceSettings?.footer?.remarks || ""
      });
    }
  }, [restaurantInfo]);

  useEffect(() => {
    const getInfo = async () => {
      const res = await getProfileInfo();
      if (res) {
        dispatch(refetchUserData());
      }
      setToggle(false);
    };
    if (toggle) {
      getInfo();
    }
  }, [toggle]);

  return (
    <InvoiceSettingContext.Provider
      value={{
        footerInfo,
        invoiceInfo,
        restaurantLogo,
        toggle,
        phoneNumber,
        initialData,
        initialFooterData,
        setFooterInfo,
        setInvoiceInfo,
        setRestaurantLogo,
        setToggle,
        setPhoneNumber
      }}
    >
      {children}
    </InvoiceSettingContext.Provider>
  );
};

export { InvoiceSettingContext, InvoiceSettingProvider };
