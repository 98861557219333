import React, { useState } from "react";
import { useSelector } from "react-redux";

const PhoneVerifyContext = React.createContext({});

const PhoneVerifyProvider = ({ children }) => {
  const userData = useSelector((state) => state?.userDataSlice?.userData);
  const [phoneNumber, setPhoneNumber] = useState(
    `${userData?.dialCode || ""}${userData?.contact}` || ""
  );
  const [dialCode, setDialCode] = useState(userData?.dialCode || "1");
  const [open, setOpen] = useState(false);

  const handleContactInput = (value, data) => {
    setPhoneNumber(value);
    setDialCode(data?.dialCode);
  };

  return (
    <PhoneVerifyContext.Provider
      value={{
        phoneNumber,
        userData,
        open,
        dialCode,
        setPhoneNumber,
        handleContactInput,
        setOpen
      }}
    >
      {children}
    </PhoneVerifyContext.Provider>
  );
};

export { PhoneVerifyContext, PhoneVerifyProvider };
