import { useState } from "react";
import { useHistory } from "react-router-dom";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { authAppleProvider } from "@configs";
import { formatFullName, setTokenData } from "@utils";
import appleSignIn from "@apicall/services/signUp/appleSignIn";
import { notifyBottomLeft } from "@atoms";

export const useAppleSignIn = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleAppleSignIn = () => {
    const auth = getAuth();
    signInWithPopup(auth, authAppleProvider)
      .then(async (result) => {
        setLoading(true);
        const credentials = OAuthProvider.credentialFromResult(result);
        // firebaseAuth.setPersistence(browserLocalPersistence);
        const token = result?.user?.accessToken;
        const fullName = result?.user?.displayName;
        if (token) {
          let name = "";
          if (fullName) {
            name = formatFullName(fullName);
          }
          const response = await appleSignIn({ token, name });
          if (response) {
            setLoading(false);
            setTokenData(response);
            localStorage.setItem("deviceToken", response?.deviceToken);
            notifyBottomLeft("Success", "Signed in Successfully");
            history.push("/restrox");
          }
        }
        setLoading(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        setLoading(false);
      });
  };

  return { loading, handleAppleSignIn };
};
