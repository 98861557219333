// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : Number(num)?.toFixed(2);

export const formatCashValue = (number = 0, type = "fixed") => {
  const units = ["", "K", "M", "B", "T"];
  const flag = number < 0 ? "-" : "";
  number = Math.abs(number);
  if (number < 1000) {
    return `${flag}${number.toFixed(2)}`;
  }
  let unitIndex = 0;
  while (number >= 1000) {
    number /= 1000;
    unitIndex++;
  }

  if (unitIndex > 4) {
    unitIndex = 4;
  }
  return `${flag} ${type === "fixed" ? number.toFixed(2) : number}${
    unitIndex > 0 ? units[unitIndex] : ""
  }`;
};

export const amountFormatter = (num) =>
  num > 99999999 ? `${(num / 1000).toFixed()}k` : Number(num)?.toFixed(2);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */

export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData");

export const isPhoneVerified = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const isPhoneVerified = userData?.isPhoneVerified || false;
  return isPhoneVerified;
};

export const isUserUnAuthorized = () => {
  return localStorage.getItem("unauthorized") || false;
};

export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#DA08191a", // for option hover bg-color
    primary: "#DA0819", // for selected option bg-color
    neutral10: "#DA0819", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
});

export const setIsWelcomePageDisplayed = () => {
  sessionStorage.setItem("isWelcomePageDisplayed", JSON.stringify(true));
};

export const isWelcomePageDisplayed = () => {
  return JSON.parse(sessionStorage.getItem("isWelcomePageDisplayed"));
};
