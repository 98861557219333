import { getApiResponse } from "@apicall";

export const getSocialLinkApi = async () => {
  const result = await getApiResponse({
    url: `/connect/socialLinks`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getRestroConnectInfo = async () => {
  const result = await getApiResponse({
    url: "/connect",
    method: "get",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const createBasicInfomationApi = async (data) => {
  const result = await getApiResponse({
    url: "/connect",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "post",
    type: "multipart/form-data",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const updateBasicInfomationApi = async (data) => {
  const result = await getApiResponse({
    url: "/connect",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "multipart/form-data",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const updateConnectSettingApi = async (data) => {
  const result = await getApiResponse({
    url: "/connect/setting",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "application/json",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const updateSocialLinkApi = async (data) => {
  const result = await getApiResponse({
    url: "/connect/socialLink",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "application/json",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const createMenuImageApi = async (data) => {
  const result = await getApiResponse({
    url: "/connect/menu",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "post",
    type: "multipart/form-data",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const deleteMenuImageApi = async (id) => {
  const result = await getApiResponse({
    url: `/connect/menu/${id}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "delete",
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const updateThridPartyLinkApi = async (data, query = "") => {
  const result = await getApiResponse({
    url: `/connect/thirdpartylink${query}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "application/json",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const toggleConnectSetVisibility = async (data) => {
  const result = await getApiResponse({
    url: "/connect/setVisibility",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "multipart/form-data",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const updateConnectVisibilityApi = async (data) => {
  const result = await getApiResponse({
    url: "/connect/setVisibility",
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "application/json",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const removeImageApi = async (image = "photo") => {
  const result = await getApiResponse({
    url: `/connect/photo?photo=${image}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "delete",
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const addSpecialDishApi = async (data) => {
  const result = await getApiResponse({
    url: `/dish/specialStatus`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "application/json",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getPopularCategoryApi = async () => {
  const result = await getApiResponse({
    url: `/category/special`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "get",
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getSpecialDishApi = async () => {
  const result = await getApiResponse({
    url: `/dish/special`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "get",
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getPopularDishApi = async () => {
  const result = await getApiResponse({
    url: `/dish/explore`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "get",
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const addPhotoGalleryApi = async (data) => {
  const result = await getApiResponse({
    url: `/connect/gallery`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "post",
    type: "multipart/form-data",
    data
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getPhotoGalleryApi = async () => {
  const result = await getApiResponse({
    url: `/connect/gallery`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "get"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getAlbumPhotoApi = async (album) => {
  const result = await getApiResponse({
    url: `/connect/albumPhoto${album === "All" ? "" : `?album=${album}`}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "get"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const deleteGalleryImage = async (id) => {
  const result = await getApiResponse({
    url: `/connect/gallery/${id}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "delete",
    type: "application/json"
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const createCustomDeliveryApi = async (payload) => {
  const result = await getApiResponse({
    url: `/connect/socialLink`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "post",
    type: "multipart/form-data",
    data: payload
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const updateCustomDeliveryApi = async (id, payload) => {
  const result = await getApiResponse({
    url: `/connect/socialLink/${id}`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "patch",
    type: "multipart/form-data",
    data: payload
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const deleteCustomDeliveryApi = async (payload) => {
  const result = await getApiResponse({
    url: `/connect/socialLink`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    version: "v3",
    method: "delete",
    type: "application/json",
    data: payload
  });

  if (result.APIFailed) return null;
  return result.data;
};
