export * from "./csv-export";
export * from "./dish-calculate";
export * from "./dish-order";
export * from "./format";
// export * from "./get-daterange";
export * from "./get-initials";
export * from "./hex-to-rgba";
export * from "./is-float";
export * from "./rgba-to-hex";
export * from "./stringToColor";
export * from "./Utils";
export * from "./validate";
export * from "./get-cropped-image";
/**
 * @description Returns Price of the Data including zero.
    Use this function except dishes and order
 * @param {*} data
 * @returns price
 */
export const getPrice = (data) => {
  if (data?.price !== undefined && data?.price !== null) {
    return data?.price || "0";
  }
  return data?.price || "0";
};

export const getDishPrice = (price) => {
  if (price !== undefined && price !== null && price !== "") {
    return price || "0";
  }
  return price;
};

export const calculateTipAmount = (totalAmount, tipAmount) => {
  const amount = (tipAmount / 100) * totalAmount;
  return Number(amount.toFixed(2));
};

export const checkDiscountAndServiceCharge = (discount, serviceCharge) => {
  if (discount && Number(discount) !== 0) {
    return true;
  }
  if (serviceCharge && Number(serviceCharge) !== 0) {
    return true;
  }
  return false;
};

export const checkTaxAndTips = (tax, tips) => {
  if (tax && Number(tax) !== 0) {
    return true;
  }
  if (tips && Number(tips) !== 0) {
    return true;
  }
  return false;
};

export const copyToClipboard = (text) =>
  new Promise((resolve, reject) => {
    if (navigator?.clipboard) {
      const cb = navigator.clipboard;

      cb.writeText(text).then(resolve).catch(reject);
    } else {
      try {
        const body = document.querySelector("body");

        const textarea = document.createElement("textarea");
        body?.appendChild(textarea);

        textarea.value = text;
        textarea.select();
        document.execCommand("copy");

        body?.removeChild(textarea);

        resolve();
      } catch (e) {
        reject(e);
      }
    }
  });

export const getDishAvailability = (status) => {
  if (status === undefined) {
    return false;
  }
  return status;
};

export const getNameOrEmail = (name = "", email = "") => {
  if (name.trim().length) {
    return name;
  } else {
    return email?.split("@")[0] || "";
  }
};

export const getKotData = (kotData) => {
  const kotList = kotData
    ?.filter((kot) => kot?.status !== "cancelled")
    .reduce((acc, curr) => {
      if (acc[curr.createdBy]) {
        acc[curr.createdBy] = acc[curr.createdBy] + `, KOT ${curr.orderId}`;
      } else {
        acc[curr.createdBy] = `KOT ${curr.orderId.toString()}`;
      }
      return acc;
    }, {});

  return kotList;
};

export const getPartialPayments = (modes = []) => {
  const data = modes?.filter((val) => {
    if (val?.name !== "Credit") return `${val?.name} (${val?.amount})`;
  });
  if (data?.length) {
    const payments = data
      ?.map((val) => `${val?.name}(${val?.amount})`)
      ?.join(", ");
    return payments;
  }
  return "";
};

export const getDefaultPaymentMode = (paymentMethods = []) => {
  const defaultPayment = paymentMethods?.find(
    (value) => value.isDefault === true
  );

  return defaultPayment;
};

export const getAdditionalChargePer = (billDetail) => {
  return (
    ((billDetail?.additionalCharge?.amount || 0) /
      (billDetail?.grandTotal -
        (billDetail?.additionalCharge?.amount || 0) -
        (billDetail?.customerDue?.due || 0))) *
    100
  )?.toFixed(2);
};
