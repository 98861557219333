import { getApiResponse } from "@apicall";

const getAllCustomersApi = async (props = {}) => {
  const { page = 1, sort = undefined, count = 25 } = props;

  const result = await getApiResponse({
    url: `/customer/`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    ofRestaurant: true,
    otherParams: {
      page,
      sort,
      count
    }
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getCustomersApi = async () => {
  const result = await getApiResponse({
    url: `/customer/?forDropDown=true`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default getAllCustomersApi;
