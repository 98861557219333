import { getApiResponse } from "@apicall";

const fetchCustomerApi = async ({
  sortAs = null,
  pageNumber = null,
  searchValue = null,
  forDropdown = false,
} = {}) => {
  const result = await getApiResponse({
    url: `/customer`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true,
    otherParams: {
      sort: sortAs,
      page: pageNumber,
      query: searchValue,
      forDropDown: forDropdown,
    },
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default fetchCustomerApi;
