import React, { forwardRef } from "react";
import { Label } from "reactstrap";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const RestroXLabel = forwardRef((props, ref) => {
  const { label = "", required, className = "", labelfor, ...rest } = props;
  return (
    <Label
      ref={ref}
      className={classNames("form-label", className)}
      labelfor={labelfor || "text-label"}
      {...rest}
    >
      {label}
      {required ? <span style={{ color: "#da1414" }}>*</span> : null}
    </Label>
  );
});
