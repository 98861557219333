import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import backgroundImg2 from "@src/assets/images/switch-restaurant.png";

const MySwal = withReactContent(Swal);

const switchRestaurantAlert = async (text, skin = "light") => {
  const result = await MySwal.fire({
    title: "Switch Restaurant",
    html: `<p class="fw-bold px-2 mt-0">${text}</p>`,
    imageUrl: backgroundImg2,
    imageWidth: 200,
    imageAlt: "Custom image",
    background: skin === "light" ? "#fff" : "black",
    showCancelButton: true,
    confirmButtonText: "Yes, Switch Restaurant",
    cancelButtonText: "No, Cancel",
    customClass: {
      confirmButton: "w-100 d-block btn text-white bg-primary",
      cancelButton: "w-100 d-block mt-1 btn mb-1 outline-none",
      image: "mt-3",
      title: "h-4"
    },
    buttonsStyling: false
  });

  return result;
};

export default switchRestaurantAlert;
