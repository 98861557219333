import React, { useState } from "react";

const RequestQRContext = React.createContext({});

const RequestQRProvider = ({ children }) => {
  const [modalQRRequest, setModalQRRequest] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [toggle, setToggle] = useState(false);

  const handleRequestQR = (data) => {
    setTableData(data);
  };

  return (
    <RequestQRContext.Provider
      value={{
        modalQRRequest,
        setModalQRRequest,
        tableData,
        handleRequestQR,
        toggle,
        setToggle
      }}
    >
      {children}
    </RequestQRContext.Provider>
  );
};

export { RequestQRContext, RequestQRProvider };
