import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { isUserUnAuthorized } from "@utils";

const ProtectedRouteContext = React.createContext({});

const ProtectedRouteProvider = ({ children }) => {
  const [restaurantSelect, setRestaurantSelect] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const val = isUserUnAuthorized();
    if (val) {
      setRestaurantSelect(true);
      history.push("/users/restaurants");
    }
  }, []);

  return (
    <ProtectedRouteContext.Provider
      value={{ restaurantSelect, setRestaurantSelect }}
    >
      {children}
    </ProtectedRouteContext.Provider>
  );
};

export { ProtectedRouteContext, ProtectedRouteProvider };
