import { getApiResponse } from "@apicall";

const getAllSuppliersApi = async (props = {}) => {
  const { page = null, sort = undefined, count = 25 } = props;

  const result = await getApiResponse({
    url: `/supplier`,
    myVersion: "2.0",
    version: "v3",
    isAuthenticated: true,
    ofRestaurant: true,
    otherParams: {
      page,
      test: "check",
      sort,
      count
    }
  });

  if (result.APIFailed) return null;
  return result.data;
};

export const getSuppliersApi = async () => {
  const result = await getApiResponse({
    url: `/inventory/supplier/`,
    myVersion: "2.0",
    isAuthenticated: true,
    ofRestaurant: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default getAllSuppliersApi;
