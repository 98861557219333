import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import "./style.scss";

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: "",
      errorInfo: ""
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error?.toString()
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      errorInfo: info
    });
    Sentry.withScope((scope) => {
      scope.setTag("component", "<ErrorBoundary />");
      Object.keys(info).forEach((key) => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <p>Oops! Something went wrong 😥.</p>
          <span onClick={() => window?.location?.reload()}>Reload</span>
          {process.env.NODE_ENV === "development" ? (
            <details open>
              <summary className="pb-1 text-xl font-bold">Error Detail</summary>
              <div className="pb-1 font-medium">
                {this.state?.error || ""}
                <br />
                <hr />
                {this?.state?.errorInfo?.componentStack || ""}
              </div>
            </details>
          ) : (
            <></>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}
