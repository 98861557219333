import { useState, useEffect } from "react";
import fetchSpaceApi from "@apicall/services/space/fetchActiveSpaceApi";

const useSpace = () => {
  const [spaceList, setSpaceList] = useState([]);

  useEffect(async () => {
    const spaceResponseData = await fetchSpaceApi();
    if (!spaceResponseData) {
      return;
    }
    const normalizedSpace = Array.from(
      spaceResponseData?.spaceWithTable,
      (spaces) => {
        return { id: spaces._id, name: spaces.name };
      }
    );
    setSpaceList(normalizedSpace);
  }, []);

  return { spaceList };
};

export default useSpace;
