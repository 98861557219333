import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import { Button, Modal, ModalBody } from "reactstrap";
import { getCroppedImg } from "../../libs/utils/get-cropped-image";

export const ImageCropModal = ({
  modalOpen,
  setModalOpen,
  setCroppedImage,
  cancelModal,
  image,
  imageSize = "square"
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels, 0);
      setCroppedImage(croppedImage);
      setModalOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels, setCroppedImage, setModalOpen]);

  return (
    <Modal className="modal-dialog-centered" isOpen={modalOpen}>
      <ModalBody className="m-2">
        <div style={{ height: "400px" }}>
          <Cropper
            image={image ? image : ""}
            crop={crop}
            zoom={zoom}
            aspect={imageSize === "square" ? 1 / 1 : 16 / 9}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            showGrid={false}
          />
        </div>
      </ModalBody>
      <div className="mb-2 me-2 d-flex" style={{ justifyContent: "end" }}>
        <Button
          type="submit"
          color="secondary"
          className="me-2"
          onClick={() => cancelModal()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={() => showCroppedImage()}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
