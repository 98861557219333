import React, { forwardRef } from "react";
import { Input } from "reactstrap";

export const CheckBox = forwardRef((props, ref) => {
  return (
    <div className="form-check">
      <Input type="checkbox" ref={ref} {...props} />
    </div>
  );
});

export const CheckBoxButton = forwardRef((props, ref) => {
  const {
    checked = false,
    color = "",
    text = "",
    onChange,
    icon = undefined
  } = props;
  return (
    <div
      className={`check-box-btn color-${color} check-btn-${
        checked ? color : ""
      }`}
      onClick={onChange}
    >
      <input type="radio" checked={checked} readOnly />
      <p className="fw-bold">
        {text} {icon ? icon : ""}
      </p>
    </div>
  );
});
