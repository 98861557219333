import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { OrderContext } from "@context";
import {
  switchTable,
  switchCustomerToTable,
  switchStaffOrder
} from "@apicall/services/tableSwap";

const useSwapOrder = () => {
  const history = useHistory();
  const { setSwappable, setOpen } = useContext(OrderContext);

  const returnState = (data) => {
    const state = {
      id: data?.id,
      isCustomer: data.swtichedTo === "customer" ? true : false,
      isStaff: data.swtichedTo === "staff" ? true : false,
      isTable: data.swtichedTo === "table" ? true : false
    };
    return state;
  };

  const handleSwitchOrder = async (data, location) => {
    const { id: $id, isTable, isCustomer, isStaff } = location?.state;
    if (isCustomer) {
      const response = await switchCustomerToTable($id, data);
      if (response) {
        setSwappable(false);
        setOpen(false);
        if (
          response?.data?.id &&
          response?.data?.name &&
          response?.data?.swtichedTo
        ) {
          history.push({
            pathname: `/pos/${response?.data?.name}/${response?.data?.id}`,
            state: returnState(response.data)
          });
        } else {
          history.push("/orders/dineIn");
        }
      }
    }
    if (isTable) {
      const response = await switchTable($id, data);
      if (response) {
        setSwappable(false);
        setOpen(false);
        if (
          response?.data?.id &&
          response?.data?.name &&
          response?.data?.swtichedTo
        ) {
          history.push({
            pathname: `/pos/${response?.data?.name}/${response?.data?.id}`,
            state: returnState(response.data)
          });
        } else {
          history.push("/orders/dineIn");
        }
      }
    }
    if (isStaff) {
      const response = await switchStaffOrder($id, data);
      if (response) {
        setSwappable(false);
        setOpen(false);
        if (
          response?.data?.id &&
          response?.data?.name &&
          response?.data?.swtichedTo
        ) {
          history.push({
            pathname: `/pos/${response?.data?.name}/${response?.data?.id}`,
            state: returnState(response.data)
          });
        } else {
          history.push("/orders/dineIn");
        }
      }
    }
  };

  return { handleSwitchOrder };
};

export default useSwapOrder;
