import React, { useState } from "react";

const DeleteTableContext = React.createContext({});

const DeleteTableProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState({});
  const [type, setType] = useState("");
  const [toggle, setToggle] = useState(false);

  const handleDeleteClick = (data, type) => {
    setType(type);
    setTableData(data);
  };

  return (
    <DeleteTableContext.Provider
      value={{
        modalOpen,
        tableData,
        type,
        setModalOpen,
        handleDeleteClick,
        toggle,
        setToggle
      }}
    >
      {children}
    </DeleteTableContext.Provider>
  );
};

export { DeleteTableContext, DeleteTableProvider };
