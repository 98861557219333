import React, { useEffect, useState } from "react";
import { getSocialLinkApi } from "@apicall/services/connect";

export const useSocialLink = () => {
  const [socialLinks, setSocialLinks] = useState([]);
  const [tags, setTags] = useState([]);
  const [thirdParties, setThirdParties] = useState([]);
  useEffect(() => {
    const getSocialLink = async () => {
      const response = await getSocialLinkApi();
      if (response) {
        setSocialLinks(response?.socialLinks?.social || []);
        setTags(response?.socialLinks?.tag || []);
        setThirdParties(response?.socialLinks?.thirdparty || []);
      }
    };

    getSocialLink();
  }, []);

  return { socialLinks, tags, thirdParties };
};
