import { isValidPhoneNumber } from "libphonenumber-js";

export const formatFullName = (fullName) => {
  const full_name = fullName?.split(" ");
  const lastName = full_name[full_name?.length - 1];
  full_name?.pop();
  const firstName = full_name?.join(" ");
  const name = { first: firstName, last: lastName };
  return name || "";
};

export const validateProfileData = (
  fullName,
  emailOrPhone,
  password,
  type,
  dialCode
) => {
  const data = {};
  const full_name = fullName.split(" ");
  if (full_name.length <= 0) {
    return {
      isValidated: false,
      message: "Please enter your full name!",
      data: null
    };
  }

  const firstName = full_name[0];
  const lastName = full_name.slice(1).join(" ");

  data.name = {
    first: firstName,
    last: lastName
  };

  if (emailOrPhone.trim()) {
    if (type === "email") {
      if (emailOrPhone?.length > dialCode?.length) {
        const isValid = isValidPhoneNumber(`+${emailOrPhone}`);
        if (!isValid) {
          return { isValidated: false, message: "Please enter valid number" };
        }
        data.phone = emailOrPhone.slice(dialCode.length);
        data.dialCode = dialCode;
      }
    } else {
      data.email = emailOrPhone;
    }
  }

  data.password = password;

  return { isValidated: true, message: null, data };
};

export const setTokenData = (data) => {
  if (data?.token) {
    localStorage.setItem("token", data?.token);
  }

  if (data?.userData) {
    const userData = data?.userData;
    localStorage.setItem("userData", JSON.stringify(userData));
  }
  localStorage.removeItem("tempToken");
  localStorage.removeItem("tempUserData");
};

export const setTempTokenData = (data) => {
  if (data?.token) {
    localStorage.setItem("tempToken", data?.token);
  }

  if (data?.userData) {
    const userData = data?.userData;
    localStorage.setItem("tempUserData", JSON.stringify(userData));
  }
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};

export const validateUserData = (email_or_phone, method) => {
  if (!email_or_phone.trim()) {
    return { isValidated: false, message: `${method} required` };
  }
  if (method === "phone") {
    const isValid = isValidPhoneNumber(`+${email_or_phone}`);
    if (!isValid) {
      return { isValidated: false, message: `Invalid Phone Number` };
    }
  }
  return { isValidated: true, message: null };
};

export const getTokenData = (_token, data) => {
  const token = localStorage.getItem(_token);
  const userData = localStorage.getItem(data);

  return { token, userData };
};

export const removeTokenData = () => {
  localStorage.removeItem("tempToken");
  localStorage.removeItem("tempUserData");
};

export const validatePhoneNumber = (phoneNumber) => {
  const isValid = isValidPhoneNumber(`+${phoneNumber}`);
  return isValid;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateCashInOutData = ({ amount, paymentMethod }) => {
  if (!amount) {
    return { isValidated: false, message: "Plese enter Amount!" };
  }
  if (!paymentMethod) {
    return { isValidated: false, message: "Please select Payment Method!" };
  }
  return { isValidated: true, message: null };
};

export const validateIncomeExpense = ({
  amount,
  paymentMethod,
  status,
  user
}) => {
  if (!amount) {
    return { isValidated: false, message: "Plese enter Amount!" };
  }

  if (status === "paid" && !paymentMethod) {
    return { isValidated: false, message: "Please select Payment Method!" };
  }

  if (status === "unpaid" && !user?.value) {
    return {
      isValidated: false,
      message:
        "You need to select User if the income/expense is due or pending!"
    };
  }

  return { isValidated: true, message: null };
};
