import { Suspense, lazy, React } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import { store } from "./redux/store";
import { ToastContainer } from "react-toastify";

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner";
// ** Ripple Button
import "./@core/components/ripple-button";
// ** PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import "prismjs/components/prism-jsx.min";
// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";
// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css";
import "./@core/scss/core.scss";
import "./assets/scss/style.scss";
// ** Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// Ability
import {
  AbilityContext,
  SwitchRestaurantProvider,
  RefreshAppProvider,
  ThemeContext
} from "@context";
import ability from "./configs/ability/ability";
import { ErrorBoundary } from "./reusable/errorBoundary";
// ** Lazy load app
const LazyApp = lazy(() => import("./App"));

Sentry.init({
  dsn:
    process.env.NODE_ENV === "development"
      ? ""
      : process.env.REACT_APP_SENTRY_KEY || "",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

if (window !== window.top) {
  // If running inside an iframe, redirect or display an error message
  window.top.location.href = "https://restrox.co";
  // Or display an error message within the iframe
  // document.body.innerHTML = '<h1>This app cannot be loaded within an iframe.</h1>';
} else {
  ReactDOM.render(
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <AbilityContext.Provider value={ability}>
          <ErrorBoundary>
            <ThemeContext>
              <RefreshAppProvider>
                <SwitchRestaurantProvider>
                  <LazyApp />
                </SwitchRestaurantProvider>
              </RefreshAppProvider>
              <ToastContainer newestOnTop />
            </ThemeContext>
          </ErrorBoundary>
        </AbilityContext.Provider>
      </Suspense>
    </Provider>,
    document.getElementById("root")
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// serviceWorkerRegistration.unregister();
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
