import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  refetch: true,
};

export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    setReservationStatus: (state, action) => {
      state.refetch = action.payload;
    },
  },
});

export const { setReservationStatus } = reservationSlice.actions;
export default reservationSlice.reducer;
