import React, { useEffect, useState } from "react";
import { getInventoryById } from "@apicall/services/inventoryInfo";

const useInventoryInfo = (id) => {
  const [inventoryDetail, setInventoryDetail] = useState({});
  const [toggleDetail, setToggleDetail] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getInventoryInfo = async () => {
      const response = await getInventoryById(id);
      if (response) {
        setInventoryDetail(response);
      }
      setLoading(false);
    };

    getInventoryInfo();
  }, [toggleDetail]);

  return { inventoryDetail, loading, toggleDetail, setToggleDetail };
};

export { useInventoryInfo };
