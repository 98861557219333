import { getApiResponse } from "@apicall";

const setRestaurantActiveApi = async ({
  restaurantId = null,
  userId = null,
}) => {
  const result = await getApiResponse({
    url: `/employee/${userId}/restaurant/${restaurantId}/active`,
    myVersion: "2.0",
    method: "put",

    isAuthenticated: true,
  });
  if (result.APIFailed) return null;
  return result.data;
};

export default setRestaurantActiveApi;
