import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  browserLocalPersistence,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup
} from "firebase/auth";
import { authProvider, firebaseAuth } from "@configs";
import { formatFullName, setTokenData } from "@utils"; // validate.js
import googleSignIn from "@apicall/services/signUp/googleSignIn";
import { notifyBottomLeft } from "@atoms";

export const useGoogleSignIn = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleGoogleSignIn = () => {
    const auth = getAuth();
    signInWithPopup(auth, authProvider)
      .then(async (result) => {
        setLoading(true);
        const credentials = GoogleAuthProvider.credentialFromResult(result);
        firebaseAuth.setPersistence(browserLocalPersistence);
        const token = credentials.idToken;
        const fullName = result?.user?.displayName;
        const email = result?.user?.email;

        if (token) {
          const name = formatFullName(fullName);
          const response = await googleSignIn({ token, email, name });
          if (response) {
            setLoading(false);
            setTokenData(response);
            localStorage.setItem("deviceToken", response?.deviceToken);
            notifyBottomLeft("Success", "Signed in Successfully");
            history.push("/restrox");
          }
        }
        setLoading(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        setLoading(false);
      });
  };

  return { loading, handleGoogleSignIn };
};
