import { getApiResponse } from "@apicall";

const appleSignIn = async ({ token, name }) => {
  const result = await getApiResponse({
    url: `/employee/login/apple`,
    myVersion: "2.0",
    method: "post",
    version: "v3",
    ofRestaurant: false,
    isAuthenticated: false,
    type: "application/json",
    data: {
      token,
      name,
      from: "web"
    }
  });
  if (result.APIFailed) return null;
  return result.data;
};

export default appleSignIn;
