import React from "react";
import Select from "react-select";

const CustomSelect = ({ handleCategorySelect, optionList }) => {
  const SingleValue = ({ data }) => <div>{data.label2}</div>;

  const customComponents = {
    SingleValue
  };

  const formatGroupLabel = () => (
    <h6 className="p-1">Select what order you want</h6>
  );

  const groupedOptions = [
    {
      label: "ooo",
      options: []
    },
    {
      label: "Flavours",
      options: optionList
    }
  ];

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "transparent" : base.borderColor,
      boxShadow: state.isFocused ? "none" : base.boxShadow,
      "&:hover": {
        borderColor: state.isFocused ? "transparent" : base.borderColor
      },
      background: "none",
      color: state.isFocused ? "red" : "black"
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused ? "#DA08191a" : base.backgroundColor,
      color: state.isFocused ? "red" : "black"
    }),
    menu: (base) => ({
      ...base,
      width: "max-content",
      minWidth: "110%"
    }),
    input: () => ({
      display: "none"
    })
  };

  return (
    <Select
      placeholder={`Select`}
      components={{
        ...customComponents,
        IndicatorSeparator: () => null
      }}
      options={groupedOptions}
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary: "#DA08191a"
        }
      })}
      onChange={(selectedOptions) => handleCategorySelect(selectedOptions)}
      formatGroupLabel={formatGroupLabel}
      styles={customStyles}
      defaultValue={optionList[0]}
    />
  );
};

export default CustomSelect;
