import "@styles/react/libs/toastify/toastify.scss";
import { notifyDishError } from "@atoms";
import { getDishPrice } from ".";

export const calculateDiscount = (actualPrice = 0, discountedPrice = 0) => {
  if (Number(actualPrice) !== 0) {
    const discount = parseFloat(actualPrice) - parseFloat(discountedPrice || 0);

    const discountPercent = (discount / parseFloat(actualPrice)) * 100;
    return discountPercent?.toFixed() || 0;
  }
  return 0;
};

export const isVariantFieldComplete = (price) => {
  if (price?.toString()?.trim()) {
    return true;
  } else {
    notifyDishError("Please enter data to Calculate Inventory");
    return false;
  }
};

export const isFieldComplete = (name, price) => {
  if (name?.trim() && price?.toString()?.trim()) {
    return true;
  } else {
    notifyDishError("Please enter data to Calculate Inventory");
    return false;
  }
};

export const checkVariantName = (dishName, variant) => {
  if (dishName && variant?.length) {
    const isDuplicate = variant.some((obj) => obj?.name === dishName);

    return isDuplicate;
  }
  return false;
};

export const isAddonsFieldEmpty = (name, price) => {
  if (name?.trim() && price?.toString()?.trim()) {
    return true;
  } else {
    notifyDishError("Please enter data to Calculate Inventory");
    return false;
  }
};

export const calculateCOGS = (cogsData = []) => {
  let totalCost = 0;
  cogsData?.forEach((item) => {
    totalCost +=
      parseFloat((item?.quantity || 0).toString()) *
      parseFloat((item?.rate || 0).toString());
  });

  return totalCost;
};

export const isVariantNameEmpty = (variant) => {
  if (variant?.length > 1) {
    const isEmpty = variant?.some(
      (obj) => obj?.name === "" || obj?.price === ""
    );
    return isEmpty;
  } else {
    return false;
  }
};

export const isAddOnNameEmpty = (addons) => {
  if (addons?.length) {
    const isEmpty = addons?.some(
      (obj) => obj?.name === "" || obj?.price === ""
    );
    return isEmpty;
  } else {
    return false;
  }
};

export const isCOGSFieldsEmpty = (cogsData = []) => {
  if (cogsData?.length === 0) {
    return true;
  }
  const isEmpty = cogsData.some(
    (obj) =>
      obj?.inventoryId === "" ||
      obj?.name === "" ||
      obj?.quantity === "" ||
      obj?.uom === "" ||
      obj?.rate === ""
  );

  return isEmpty;
};

export const isFieldEmpty = (data) => {
  if (!data?.itemName?.trim()?.length) {
    return { error: true, message: "Dish name required!" };
  }
  if (!data?.addedMenuId?.trim()?.length) {
    return { error: true, message: "Sub Menu required!" };
  }
  if (!data?.variants?.length) {
    return { error: true, message: "Variant or Price detail required!" };
  }

  return { error: false, message: "" };
};

export const DEFAULT_MATERIAL = {
  inventoryId: "",
  name: "",
  quantity: "",
  uom: "",
  rate: "",
  cogs: 0
};

export const isGradePointHigh = (data) => {
  const gradePoint =
    (getDishPrice(data?.discountedPrice) || data?.price) - data?.cogsTotal || 0;

  if (gradePoint >= 0) {
    if (gradePoint === 0) {
      return "";
    } else {
      return "gp-high";
    }
  } else {
    return "gp-low";
  }
};

export const gradePoint = (data) => {
  const gradePoint =
    (getDishPrice(data?.discountedPrice) || data?.price) - data?.cogsTotal || 0;

  if (gradePoint >= 0) {
    if (gradePoint === 0) {
      return "Gross Profit";
    } else {
      return "Gross Profit";
    }
  } else {
    return "Gross Loss";
  }
};

const isDecimal = (number) => {
  return !Number.isInteger(number);
};

export const formatDishDetail = (response) => {
  const dishData = {
    itemName: response?.item?.itemName,
    description: response?.item?.description || "",
    addedMenuId: response?.menuList[0]?.menuId?._id || "",
    addedCategoryId: response?.categoriesList[0]?.categoryId?._id || "",
    // defaultVariantName:
    // response?.item?.variants[0]?.name || response.item?.itemName || "",
    variants: response?.item?.variants || [],
    addons: response?.item?.addons || [],
    libraryImageUrl:
      response?.item?.libraryImageUrl || response?.item?.images[0] || "",
    coverImage: response?.item?.images[0] || ""
  };

  if (response?.item?.variants?.length) {
    const newVariants = response?.item?.variants.map((val) => {
      if (val?.price && getDishPrice(val?.discountedPrice)) {
        return {
          ...val,
          discountPrice: isDecimal(
            (val?.price || 0) - (val?.discountedPrice || 0)
          )
            ? ((val?.price || 0) - (val?.discountedPrice || 0)).toFixed(2)
            : 0
        };
      } else {
        return { ...val, discountPrice: "" };
      }
    });
    dishData.variants = newVariants;
  }

  const menuCategory = {};
  if (response?.categoriesList?.length) {
    menuCategory.category = {
      label: response?.categoriesList[0]?.categoryId?.categoryName,
      value: response?.categoriesList[0]?.categoryId?._id
    };
  }
  if (response?.menuList?.length) {
    menuCategory.menu = {
      label: response?.menuList[0]?.menuId?.menuName,
      value: response?.menuList[0]?.menuId?._id
    };
  }

  return { dishData, menuCategory };
};

export const isDefaultvariant = (variants, dishName) => {
  if (
    variants?.length &&
    variants?.length === 1 &&
    (variants[0]?.name === dishName || variants[0]?.name === "default")
  ) {
    return true;
  }
  return false;
};

export const getInventoryName = (dishName = "", variantName = "") => {
  if (dishName === variantName) {
    return variantName || "";
  } else {
    return `${dishName} - ${variantName}` || "";
  }
};

export const isFieldDirty = (data) => {
  if (
    data.itemName !== "" ||
    data.description !== "" ||
    data.addedCategoryId !== "" ||
    data.addedMenuId !== "" ||
    data.libraryImageUrl !== "" ||
    data.coverImage !== "" ||
    data.variants.length > 0
  ) {
    return true;
  }
  return false;
};

export const isUpdateFieldDirty = (initialData, data) => {
  const newVariants = data?.variants.map((val) => {
    return {
      ...val,
      price: Number(val?.price) || 0,
      discountedPrice:
        val?.discountedPrice === null ? null : Number(val?.discountedPrice)
    };
  });

  const newAddons = data?.addons.map((val) => {
    return {
      ...val,
      price: Number(val?.price) || 0
    };
  });

  data.variants = newVariants;
  data.addons = newAddons;

  if (initialData === JSON.stringify(data)) {
    return false;
  }
  return true;
};

export const inventoryFieldDirty = (initialData, data) => {
  if (initialData === JSON.stringify(data)) {
    return false;
  }
  return true;
};

export const calculateDiscountPrice = (price, discount) => {
  if (price && discount !== undefined && discount !== null && discount !== "") {
    return Number(price) - Number(discount);
  }
  return "";
};
