import { useState, useEffect } from "react";
import { getAllStaff } from "@apicall/services/staff/fetchStaffByFilter";
import { getCustomersApi } from "@apicall/services/account/customer/getAllCustomersApi";
import { getSuppliersApi } from "@apicall/services/supplier/getAllSuppliersApi";

export const useMemberInfo = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [supplierNewData, setSupplierNewData] = useState({});
  const [customerNewData, setCustomerNewData] = useState(false);

  useEffect(() => {
    const getAllData = async () => {
      const [suppliers, customers, staffs] = await Promise.all([
        getSuppliersApi(),
        getCustomersApi(),
        getAllStaff()
      ]);

      if (suppliers) {
        const normalizedSuppliers = Array.from(
          suppliers?.suppliers || [],
          (supplier) => {
            return {
              label: supplier.name,
              value: supplier._id,
              id: supplier._id,
              name: supplier.name,
              email: supplier?.email || ""
            };
          }
        );
        setSuppliers(normalizedSuppliers || []);
      }
      if (customers) {
        const normalizedCustomers = Array.from(
          customers?.customers || [],
          (customer) => {
            return {
              label: customer?.name || "",
              value: customer?._id || "",
              id: customer?._id || "",
              name: customer?.name || "",
              email: customer?.email || ""
            };
          }
        );
        setCustomers(normalizedCustomers || []);
      }
      if (staffs) {
        const normalizedStaffs = Array.from(
          staffs?.employees || [],
          (staff) => {
            return {
              label: staff?.name || staff?.email?.split("@")[0] || "",
              value: staff?._id || "",
              id: staff?._id || "",
              name: staff?.name || staff?.email?.split("@")[0] || "",
              email: staff?.email || "",
              due: staff?.totalDue || ""
            };
          }
        );
        setStaffs(normalizedStaffs || []);
        const normalizedEmployees = Array.from(
          staffs?.employees || [],
          (staff) => {
            return {
              label: staff?.name || staff?.email?.split("@")[0] || "",
              value: staff?.employeeId?._id || "",
              id: staff?.employeeId?._id || "",
              name: staff?.name || staff?.email?.split("@")[0] || "",
              email: staff?.email || "",
              due: staff?.totalDue || ""
            };
          }
        );
        setEmployees(normalizedEmployees || []);
      }
    };
    getAllData();
  }, []);

  useEffect(() => {
    if (customerNewData) {
      const getCustomerData = async () => {
        const customers = await getCustomersApi();

        if (customers) {
          const normalizedCustomers = Array.from(
            customers?.customers || [],
            (customer) => {
              return {
                label: customer.name,
                value: customer._id,
                id: customer._id,
                name: customer.name,
                email: customer?.email || ""
              };
            }
          );
          setCustomers(normalizedCustomers || []);
        }
        setCustomerNewData(false);
      };
      getCustomerData();
    }
  }, [customerNewData]);

  useEffect(() => {
    if (supplierNewData) {
      const getSupplierData = async () => {
        const suppliers = await getSuppliersApi();

        if (suppliers) {
          const normalizedSuppliers = Array.from(
            suppliers?.suppliers || [],
            (supplier) => {
              return {
                label: supplier.name,
                value: supplier._id,
                id: supplier._id,
                name: supplier.name,
                email: supplier?.email || ""
              };
            }
          );
          setSuppliers(normalizedSuppliers || []);
        }
        setSupplierNewData(false);
      };
      getSupplierData();
    }
  }, [supplierNewData]);

  return {
    suppliers,
    customers,
    staffs,
    employees,
    setCustomerNewData,
    setSupplierNewData
  };
};

export const useStaffInfo = (isStaff) => {
  const [staffs, setStaffs] = useState([]);
  const [employees, setEmployees] = useState([]); //restaurant employees

  useEffect(() => {
    const getAllData = async () => {
      const staffs = await getAllStaff();

      if (staffs) {
        const normalizedStaffs = Array.from(
          staffs?.employees || [],
          (staff) => {
            return {
              label: staff?.name || "",
              value: staff?._id || "",
              id: staff?._id || "",
              name: staff?.name || "",
              email: staff?.email || "",
              due: staff?.totalDue || 0
            };
          }
        );
        const normalizedEmployees = Array.from(
          staffs?.employees || [],
          (staff) => {
            return {
              label: staff?.name || "",
              value: staff?.employeeId?._id || "",
              id: staff?.employeeId?._id || "",
              name: staff?.name || "",
              email: staff?.email || "",
              due: staff?.totalDue || ""
            };
          }
        );
        setStaffs(normalizedStaffs || []);
        setEmployees(normalizedEmployees || []);
      }
    };
    // if (isStaff) {
    getAllData();
    // }
  }, []);

  return { staffs, employees };
};

export default useMemberInfo;
