import { initializeApp } from "@firebase/app";
import {
  browserLocalPersistence,
  setPersistence,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);

const firebaseAuth = getAuth(app);
// firebaseAuth.settings.appVerificationDisabledForTesting = true;
// firebaseAuth.languageCode = "it";
const authProvider = new GoogleAuthProvider();

const authAppleProvider = new OAuthProvider("apple.com");
authAppleProvider.addScope("email");
authAppleProvider.addScope("name");
// authProvider.addScope("email");
// authProvider.addScope("profile");
setPersistence(firebaseAuth, browserLocalPersistence);

export { authProvider, authAppleProvider, firebaseAuth };
