import { getApiResponse } from "@apicall";

export const getInventoryById = async (id = "") => {
  const response = await getApiResponse({
    url: `/inventory/${id}`,
    myVersion: "2.0",
    ofRestaurant: true,
    version: "v3",
    method: "get",
    isAuthenticated: true
  });

  if (response.APIFailed) return null;
  return response.data;
};
