const baseURL = process.env.REACT_APP_SERVER_URL;

const getHeader = ({ isAuthenticated, type = "application/json" }) => {
  const storedToken = localStorage.getItem("token");
  const headers = {};
  headers["Content-Type"] = type;

  if (isAuthenticated) {
    headers["Authorization"] = `Bearer ${storedToken}`;
  }

  return headers;
};

const handleError = (err) => {
  if (err) {
    return "Something went wrong";
  }
};

const api = {
  get: async ({ url, isAuthenticated }) => {
    try {
      const headers = getHeader({ isAuthenticated: isAuthenticated || false });
      const config = { headers };
      const response = await fetch(`${baseURL}/${url}`, config);
      const data = await response.json();

      return data;
    } catch (error) {
      const message = handleError(error);

      return { status: "failure", message };
    }
  },

  post: async ({
    url,
    data,
    isAuthenticated = false,
    type = "application/json"
  }) => {
    try {
      const headers = getHeader({
        isAuthenticated: isAuthenticated || false,
        type
      });

      const config = {
        headers,
        method: "POST",
        body: JSON.stringify(data)
      };
      const response = await fetch(`${baseURL}${url}`, config);
      const dataToSend = await response.json();
      return dataToSend;
    } catch (error) {
      const message = handleError(error);

      return { status: "failure", message };
    }
  },

  patch: async ({
    url,
    data,
    isAuthenticated = false,
    type = "application/json"
  }) => {
    try {
      const headers = getHeader({
        isAuthenticated: isAuthenticated || false,
        type
      });

      const config = {
        headers,
        method: "PATCH",
        body: JSON.stringify(data)
      };

      const response = await fetch(`${baseURL}${url}`, config);
      const dataToSend = await response.json();
      return dataToSend;
    } catch (error) {
      const message = handleError(error);

      return { status: "failure", message };
    }
  },

  delete: async ({
    url,
    data,
    isAuthenticated = false,
    type = "application/json"
  }) => {
    try {
      const headers = getHeader({
        isAuthenticated: isAuthenticated || false,
        type
      });

      const config = {
        headers,
        method: "DELETE"
      };

      const response = await fetch(`${baseURL}${url}`, config);

      const dataToSend = await response.json();
      return { status: "success", data: dataToSend };
    } catch (error) {
      const message = handleError(error);
      return { status: "failure", message };
    }
  }
};

export default api;
