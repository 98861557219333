import React, { useState } from "react";

const OrderContext = React.createContext({});

const OrderProvider = ({ children }) => {
  const [customizable, setCustomizable] = useState(false);
  const [orderModal, setOrderModal] = useState(false);
  const [dishData, setDishData] = useState({});
  const [prevOrder, setPrevOrder] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [swappable, setSwappable] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [open, setOpen] = useState(false);
  const [orderType, setOrderType] = useState({
    type: "",
    name: "",
    id: ""
  });
  const [orderOption, setOrderOption] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("dineIn");

  const handleOptionSelect = (data) => {
    setOrderType(data);
    setOrderOption(false);
  };

  return (
    <OrderContext.Provider
      value={{
        customizable,
        orderModal,
        dishData,
        prevOrder,
        orderList,
        swappable,
        orderDetail,
        open,
        orderType,
        orderOption,
        orderOpen,
        activeTab,
        setActiveTab,
        setOrderOpen,
        setDishData,
        setOrderModal,
        setCustomizable,
        setPrevOrder,
        setOrderList,
        setSwappable,
        setOrderDetail,
        setOpen,
        setOrderType,
        setOrderOption,
        handleOptionSelect
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext, OrderProvider };
