import React, { useState, useEffect } from "react";
import getAllInvenoryGroupApi from "@apicall/services/inventoryGroup/getAllInventoryGroupApi";

export const useInventoryGroup = () => {
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    const getAllInventory = async () => {
      const response = await getAllInvenoryGroupApi({ forDropDown: true });
      if (response) {
        const normalizedList = Array.from(response || [], (group) => {
          return { id: group?._id || "", name: group?.name || "" };
        });
        setGroupList(normalizedList || []);
      }
    };
    getAllInventory();
  }, []);

  return { groupList };
};
