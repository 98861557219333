// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import userDataSlice from "./userDataSlice/userDataSlice";
import reservationSlice from "./reservationSlice/reservationSlice";

const rootReducer = {
  auth,
  navbar,
  layout,
  navbar,
  userDataSlice,
  reservationSlice,
};

export default rootReducer;
