import React, { useState } from "react";

const RestaurantControlContext = React.createContext({});

const RestaurantControlProvider = ({ children }) => {
  const [taxOpen, setTaxOpen] = useState(false);
  const [tipsModal, setTipsModal] = useState(false);

  return (
    <RestaurantControlContext.Provider
      value={{ taxOpen, tipsModal, setTaxOpen, setTipsModal }}
    >
      {children}
    </RestaurantControlContext.Provider>
  );
};

export { RestaurantControlContext, RestaurantControlProvider };
