import React from "react";
import ReactPaginate from "react-paginate";
import Proptypes from "prop-types";

export const RestroXPagination = ({ pageInfo, handlePagination }) => {
  return (
    <div>
      <ReactPaginate
        previousLabel={""}
        nextLabel={""}
        breakLabel="..."
        pageCount={pageInfo.noOfPage}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        activeClassName="active"
        forcePage={pageInfo.number !== 0 ? pageInfo.number - 1 : 0}
        onPageChange={(page) => handlePagination(page)}
        pageClassName="page-item"
        breakClassName="page-item"
        nextLinkClassName="page-link"
        pageLinkClassName="page-link"
        breakLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextClassName="page-item next-item"
        previousClassName="page-item prev-item"
        containerClassName={
          "pagination react-paginate separated-pagination pagination-md justify-content-end pt-1 px-1"
        }
      />
    </div>
  );
};

RestroXPagination.propTypes = {
  pageInfo: Proptypes.object,
  handlePagination: Proptypes.func
};
