import { getApiResponse } from "@apicall";

const getAllRestaurantsApi = async ({ userId = null }) => {
  const result = await getApiResponse({
    url: `/employee/${userId}/restaurant`,
    myVersion: "2.0",
    ofRestaurant: false,
    method: "get",
    isAuthenticated: true,
  });
  if (result.APIFailed) return null;
  return result.data;
};

export default getAllRestaurantsApi;
