/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import signUpApi from "@apicall/services/signUp/signUp";
import { notifyBottomWarning, notifyBottomLeft } from "@atoms";
import { firebaseAuth } from "@configs";

const useResendOtp = ({ credential, dialCode }) => {
  const [resendLoading, setResendLoading] = useState(false);
  const [time, setTime] = useState(60);
  const [resend, setResend] = useState(false);

  const handleResendEmail = async () => {
    setResendLoading(true);
    const response = await signUpApi({
      email: credential
    });
    if (response) {
      setTime(60);
      setResend(false);
    }
    setResendLoading(false);
  };

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          setResendLoading(false);
        }
      },
      firebaseAuth
    );
  };

  const handleResendPhone = async () => {
    if (window?.recaptchaVerifier.widgetId === 0) {
      setUpRecaptcha();
    }
    const phoneNumber = `+${dialCode}${credential}`;
    const appVerifier = window.recaptchaVerifier;
    setResendLoading(true);
    signInWithPhoneNumber(firebaseAuth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        if (confirmationResult) {
          notifyBottomLeft("Success", "OTP sent to your phone!");
          window.confirmationResult = confirmationResult;
          setTime(60);
          setResend(false);
        }
        setResendLoading(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        setResendLoading(false);
        notifyBottomWarning("Error", "Error Sending Phone OTP");
      });
  };

  const handleVerifyResendPhone = async () => {
    if (!window?.recaptchaVerifier) {
      setUpRecaptcha();
    }
    const phoneNumber = `+${dialCode}${credential}`;
    const appVerifier = window.recaptchaVerifier;
    setResendLoading(true);
    signInWithPhoneNumber(firebaseAuth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        if (confirmationResult) {
          notifyBottomLeft("Success", "OTP sent to your phone!");
          window.confirmationResult = confirmationResult;
        }
        setResendLoading(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        setResendLoading(false);
        notifyBottomWarning("Error", "Error Sending Phone OTP");
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (time === 0) {
        setResend(true);
      } else {
        setTime(time - 1);
      }
    }, 1000);
    return () => clearTimeout(interval);
  }, [time]);

  return {
    resendLoading,
    time,
    resend,
    handleResendEmail,
    handleResendPhone,
    handleVerifyResendPhone,
    setTime,
    setResend
  };
};

export default useResendOtp;
