import axios from "@axios";
import { notifyBottomLeft, notifyBottomWarning } from "@atoms";

const getRestaurant = (ofRestaurant) => {
  return ofRestaurant
    ? `/restaurant/${
        JSON.parse(localStorage.getItem("userData")).activeRestaurant._id
      }`
    : "";
};

const prefix = "restrox/api";
export const getApiResponse = async ({
  url,
  method = "get",
  isAuthenticated = false,
  type = "text/plain",
  data = {},
  version = "",
  myVersion = "1.0",
  otherParams = {},
  ofRestaurant = false,
  isNewUser = false,
  showResponse = true
}) => {
  switch (myVersion) {
    case "2.0":
      try {
        const myHeader = {
          "Content-Type": type
        };
        if (isAuthenticated) {
          myHeader.Authorization = `Bearer ${localStorage.getItem("token")}`;
        }
        if (isNewUser) {
          myHeader.Authorization = `Bearer ${localStorage.getItem(
            "tempToken"
          )}`;
        }

        const result = await axios({
          method,

          url: `${prefix}${version.length ? `/${version}` : ""}${getRestaurant(
            ofRestaurant
          )}${url}`,
          data,

          headers: myHeader,
          params: {
            ...otherParams
          },
          accept: "*/*"
        });
        if (result?.data?.token) {
          localStorage.setItem("token", result.data.token);
        }
        if (result?.data?.userData) {
          const userData = result.data.userData;
          localStorage.setItem("userData", JSON.stringify(userData));
        }
        if (
          result.status === 200 &&
          method !== "get" &&
          result?.data?.message &&
          showResponse
        ) {
          notifyBottomLeft("Success", result?.data?.message);
        }
        return result;
      } catch (error) {
        if (method !== "get") {
          notifyBottomWarning("Error", error.response?.data?.message);
        }

        if (error?.response?.status === 410) {
          localStorage.setItem("unauthorized", true);
        }

        return {
          APIFailed: true,
          error: error.response
        };
      }

    default:
      try {
        const myHeader = {
          "Content-Type": type
        };

        if (isAuthenticated) {
          myHeader.Authorization = `Bearer ${localStorage.getItem("token")}`;
        }

        const result = await axios({
          method,
          url,
          data,
          headers: myHeader,
          params: {
            version,
            ...otherParams
          },
          accept: "*/*"
        });
        if (result.status === 200 && method !== "get" && showResponse) {
          notifyBottomLeft("Success", result?.data?.message);
        }
        return result;
      } catch (error) {
        if (method !== "get") {
          notifyBottomWarning("Error", error.response.data.message);
        }

        if (error?.response?.status === 410) {
          localStorage.setItem("unauthorized", true);
        }

        return {
          APIFailed: true,
          error: error.response
        };
      }
      break;
  }
};
