import React from "react";
import { useSelector } from "react-redux";

export const useUserInfo = () => {
  const userData = useSelector((state) => state?.userDataSlice?.userData);
  return {
    fullName: `${userData?.name?.first || ""} ${userData?.name?.last}`,
    userData
  };
};
