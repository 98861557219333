import { getApiResponse } from "@apicall";

const getActiveMenuCategory = async () => {
  const categoryRes = await getApiResponse({
    url: `/menu/active/categories`,
    myVersion: "2.0",
    version: "v2",
    isAuthenticated: true,
    ofRestaurant: true
  });

  if (categoryRes.APIFailed) {
    if (categoryRes?.error?.status === 409) {
      return categoryRes.error.status;
    }
  }
  return categoryRes.data;
};

export const getOrderMenuCategory = async () => {
  const categoryRes = await getApiResponse({
    url: `/menu/active/categories/digital-menu?menuType=dineIn`,
    myVersion: "2.0",
    version: "v2",
    isAuthenticated: true,
    ofRestaurant: true
  });

  if (categoryRes.APIFailed) {
    if (categoryRes?.error?.status === 409) {
      return categoryRes?.error?.status;
    }
  }
  return categoryRes.data;
};

export default getActiveMenuCategory;
