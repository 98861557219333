import React from "react";
import { useSelector } from "react-redux";
import CurrencyFormatter from "currency-formatter";

const useCurrency = () => {
  const { name, symbol } = useSelector(
    (state) =>
      state?.userDataSlice?.userData?.activeRestaurant?.currency || {
        name: "NPR",
        symbol: "NPRs"
      }
  );

  const currencySymbol = CurrencyFormatter.findCurrency(name);

  const formatCurrency = (value = 0) => {
    if (name === "NPR") {
      return (
        <>
          Rs.{" "}
          {CurrencyFormatter.format(Number(value)?.toFixed(2), {
            code: name,
            format: "%v"
          })}
        </>
      );
    }
    return (
      <>
        {CurrencyFormatter.format(Number(value)?.toFixed(2), {
          code: name,
          format: "%s%v"
        })}
      </>
    );
  };

  const formatInvoiceCurrency = (value = 0, currencyName) => {
    return CurrencyFormatter.format(Number(value)?.toFixed(2), {
      code: currencyName || name || "NPR",
      format: "%s %v"
    });
  };

  const getCurrencySymbol = (code) => {
    const currencySymbol = CurrencyFormatter.findCurrency(code || name);
    return currencySymbol?.symbol;
  };

  return {
    name,
    symbol,
    currencySymbol,
    currency: currencySymbol ? currencySymbol?.symbol : symbol,
    formatCurrency,
    formatInvoiceCurrency,
    getCurrencySymbol
  };
};

export { useCurrency };
