import moment from "moment/moment";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const convertArrayOfObjectsToCSV = (array) => {
  let result;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (typeof item[key] === "object") {
        result += item[key]?.name;
      } else {
        result += item[key];
      }
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadCSV = (array, name, format) => {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv === null) return;

  const filename = `${name}.${format}`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  if (format === "xlsx") {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const ws = XLSX.utils.json_to_sheet(array);
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const maxLengths = array.reduce((acc, row) => {
      Object.keys(row).forEach((key, index) => {
        if (!acc[index] || row[key].length > acc[index]) {
          acc[index] = row[key].length;
        }
      });
      return acc;
    }, []);

    const ws = XLSX.utils.json_to_sheet(array);
    ws["!cols"] = maxLengths.map((len) => ({ wch: len }));
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, name + format);
  } else {
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }
};

const getPrice = (data) => {
  if (data?.price && data?.price?.length) {
    const price = data?.price[0]?.price || 0;
    const discount = data?.price[0]?.discount?.amount || 0;
    const originalPrice = data?.price[0]?.discountedPrice || price - discount;
    return originalPrice;
  } else {
    return data?.price || "N/A";
  }
};

const getCategoryList = (categories) => {
  if (!categories || !categories?.length) return "no category";

  const normalizedCategory = categories
    .map((category) => {
      return category?.categoryId?.categoryName;
    })
    .join(" ");

  return normalizedCategory;
};

const getMenuList = (menu) => {
  if (!menu || !menu?.length) return "no menu";

  return menu
    .map((item) => {
      return item?.menuId?.menuName;
    })
    .join(" ");
};

const getInventoryGroups = (groups) => {
  return groups
    .map((item) => {
      return item?.name;
    })
    .join(" ");
};

const getUserName = (data) => {
  if (data?.user) {
    return `${data?.user?.name?.first} ${data?.user?.name?.last}`;
  }
  return "";
};

const getStaffName = (data) => {
  if (data?.staff) {
    return `${data?.staff?.name || "N/A"} `;
  }
  return "";
};

const getCustomerName = (data) => {
  if (data?.customer) {
    return `${data?.customer?.name || "N/A"}`;
  }
  return "";
};

const getSupplierName = (data) => {
  if (data?.customer) {
    return `${data?.supplier?.name | "N/A"}`;
  }
  return "";
};

const convertTableListToCSV = (tableData) => {
  let result;
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(tableData[0]);
  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  tableData.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (key === "images") {
        if (item?.images?.length > 0) {
          result += item[key].join(",");
        } else {
          result += "empty";
        }
      } else if (key === "avgReview") {
        result += item[key]?.noOfReview;
      } else if (key === "discount") {
        result += item[key]?.amount;
      } else if (key === "price") {
        result += getPrice(item);
      } else if (typeof key === "name") {
        result += item[key]?.name || "N/A";
      } else if (typeof item[key] === "createdAt") {
        const createdAt = moment(item?.createdAt).format(
          "YYYY-MM-DD h:mm:ss a"
        );
        result += createdAt || item[key]?.createdAt || "N/A";
      } else if (typeof key === "email") {
        result += item[key]?.email || "N/A";
      } else if (typeof key === "contact") {
        result += item[key]?.contact.toFixed(0) || "N/A";
      } else if (key === "unit") {
        result += item[key]?.nickName;
      } else if (key === "stock") {
        result += `undistributed: ${item[key]?.undistributed} | distributed: ${item[key]?.distributed}`;
      } else if (key === "groups") {
        result += getInventoryGroups(item?.groups);
      } else if (key === "restock") {
        result += `level: ${item[key]?.level} | quantity: ${item[key]?.quantity}`;
      } else if (key === "categories") {
        result += getCategoryList(item?.categories);
      } else if (key === "menus") {
        result += getMenuList(item?.menus);
      } else if (key === "user") {
        result += getUserName(item);
      } else if (key === "staff") {
        result += getStaffName(item);
      } else if (key === "supplier") {
        result += getSupplierName(item);
      } else if (key === "customer") {
        result += getCustomerName(item);
      } else if (typeof item[key] === "object") {
        result += item[key]?.name || item[key] || "null";
      } else if (typeof item[key] === "description") {
        result += item[key]?.name || item[key] || "N/A";
      } else {
        result += item[key] || "N/A";
      }
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const exportTable = (tableData, name, format) => {
  const link = document.createElement("a");

  const exportList = tableData.map((list) => {
    list.price = list.variants;
    delete list.variants;
    const { _id, deletedBy, deliveryDetails, itemId, ...rest } = list;
    return rest;
  });
  let tableCSV = convertTableListToCSV(exportList);
  if (tableCSV === null) return;

  const filename = `${name}.${format}`;

  if (!tableCSV.match(/^data:text\/csv/i)) {
    tableCSV = `data:text/csv;charset=utf-8,${tableCSV}`;
  }

  if (format === "xlsx") {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    // const ws = XLSX.utils.json_to_sheet(exportList);
    // const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const maxLengths = exportList.reduce((acc, row) => {
      Object.keys(row).forEach((key, index) => {
        if (!acc[index] || row[key]?.length > acc[index]) {
          acc[index] = row[key]?.length;
        }
      });
      return acc;
    }, []);

    const ws = XLSX.utils.json_to_sheet(exportList);
    ws["!cols"] = maxLengths.map((len) => ({ wch: len }));
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, name + format);
  } else {
    link.setAttribute("href", encodeURI(tableCSV));
    link.setAttribute("download", filename);
    link.click();
  }
};
export const exportDishTable = (tableData, name, format) => {
  const link = document.createElement("a");

  const newList = [];
  tableData.map((list) => {
    list.price = list.variants;

    newList.push({
      ["Dish Name"]: `"${list?.itemName}"` || "N/A",
      ["Menu"]: `"${getMenuList(list?.menus)}"`,
      ["Category"]: `"${getCategoryList(list?.categories)}"`,
      price: list.price,
      ["Description"]: list?.description || ""
    });
    return list;
  });
  let tableCSV = convertTableListToCSV(newList);
  if (tableCSV === null) return;

  const filename = `${name}.${format}`;

  if (!tableCSV.match(/^data:text\/csv/i)) {
    tableCSV = `data:text/csv;charset=utf-8,${tableCSV}`;
  }

  if (format === "xlsx") {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const maxLengths = newList.reduce((acc, row) => {
      Object.keys(row).forEach((key, index) => {
        if (!acc[index] || row[key]?.length > acc[index]) {
          acc[index] = row[key]?.length;
        }
      });
      return acc;
    }, []);
    const ws = XLSX.utils.json_to_sheet(newList);
    ws["!cols"] = maxLengths.map((len) => ({ wch: len }));
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, name + format);
  } else {
    link.setAttribute("href", encodeURI(tableCSV));
    link.setAttribute("download", filename);
    link.click();
  }
};
