import { getApiResponse } from "@apicall";

const getRestaurantInfo = async () => {
  const result = await getApiResponse({
    url: `/restaurantDetails`,
    myVersion: "2.0",
    version: "v3",
    ofRestaurant: true,
    method: "get",
    isAuthenticated: true
  });

  if (result.APIFailed) return null;
  return result.data;
};

export default getRestaurantInfo;
