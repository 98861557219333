import React, { useState } from "react";

const OrderCheckoutContext = React.createContext({});

const OrderCheckoutProvider = ({ children }) => {
  const [resetPartialPay, setResetPartialPay] = useState(false);
  const [partialPaymentDiscount, setPartialPaymentDiscount] = useState(0);
  const [isUpdatePartial, setIsUpdatePartial] = useState(false);
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [kotData, setKotData] = useState({});
  const [isOldDue, setIsOldDue] = useState(false);
  const [additionalCharge, setAdditionalCharge] = useState(false);
  const [chargePercent, setChargePercent] = useState("");
  const [chargeAmount, setChargeAmount] = useState("");
  const [dueAmount, setDueAmount] = useState("");
  const [loyaltyPoints, setLoyaltyPoints] = useState({
    percent: 0,
    amount: 0
  });

  const clearAdditionalField = () => {
    setIsOldDue(false);
    setAdditionalCharge(false);
    setChargePercent("");
    setChargeAmount("");
    setDueAmount("");
  };

  return (
    <OrderCheckoutContext.Provider
      value={{
        resetPartialPay,
        isUpdatePartial,
        kotData,
        isOldDue,
        additionalCharge,
        deliveryDetails,
        chargeAmount,
        chargePercent,
        dueAmount,
        partialPaymentDiscount,
        loyaltyPoints,
        setPartialPaymentDiscount,
        setIsUpdatePartial,
        setResetPartialPay,
        setDeliveryDetails,
        setKotData,
        setIsOldDue,
        setAdditionalCharge,
        setChargeAmount,
        setChargePercent,
        setDueAmount,
        clearAdditionalField,
        setLoyaltyPoints
      }}
    >
      {children}
    </OrderCheckoutContext.Provider>
  );
};

export { OrderCheckoutContext, OrderCheckoutProvider };
