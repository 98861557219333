import React from "react";
import { Spinner } from "reactstrap";

export const RestroXLoader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center pt-2 pb-2">
      <Spinner color="primary" size={"50"} />
    </div>
  );
};
